// import { UploadImage, IconSvg } from 'src/common/ui-components';

import styles from './LogoControlPanel.module.scss';
import CompanyImage from '../company-image/CompanyImage';
import { useState } from 'react';
import { IconSvg, UploadImage } from 'src/components/common';

interface Props {
  onImageAttached: (file: File) => void;
  onDeleteLogo: () => void;
  isLogo: boolean;
  imageUrl: string;
}

export default function LogoControlPanel({ onImageAttached, onDeleteLogo, isLogo,imageUrl }: Props) {

  return (
    <div
      className={
        styles['control-panel'] +
        ' flex flex-col bg-[#C56B481A] w-full p-4 border-[3px] border-dashed rounded border-[#C56B48]'
      }
    >
      
      {!isLogo && (
        <UploadImage
          accept=".jpg,.png,.pdf,.docx"
          mbSize={1}
          onFileDropSuccess={file => onImageAttached(file)}
        />
      )}
      {isLogo && (
        <CompanyImage
          // url={profile?.account.company.logo}
          url={imageUrl}
        />
      )}
      {isLogo && (
        <div className={styles['updating-panel']}>
          <div className={styles['replase-btn']}>
            <UploadImage
              accept=".jpg,.png,.pdf,.docx"
              mbSize={1}
              onFileDropSuccess={file => {
                onImageAttached(file);
              }}
              label="Replaсe"
              // iconBtn={<IconSvg icon="replaceImage" />}
            />
          </div>

          <div className={styles['delete-btn']} role="button" onClick={onDeleteLogo}>
            {/* <IconSvg className={styles['delte-icon']} icon="deleteRed" /> */}
            <span>Delete</span>
          </div>
        </div>
      )}
    </div>
  );
}
