import { ReactNode, useState } from 'react';
// import styles from '../common/InputForm.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconSvg } from 'src/components/common';
import { InputText } from 'src/components/common/form';
import { ExistingAccountInstruction } from 'src/components/forget-password/ExistingAccountInstruction';
import { useHttp, useNotification } from 'src/hooks';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

export interface forgetForm {
  email: string;
}
const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

export function ForgetPassword() {
  const navigate = useNavigate();
  const [isOpenExistingAccountModal, setIsOpenExistingAccountModal] = useState(false);
  const { sendRequest, isLoading: isLoading, error } = useHttp();
  const notify = useNotification();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: values => {
      onSubmitHandler(values);
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched, resetForm } = formik;
  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };
  const onCancel = () => {
    setIsOpenExistingAccountModal(false);
  };

  const formInputs = [
    {
      name: 'email',
      label: '',
      placeholder: 'Email',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
  ];
  const onSubmitHandler = (payload: forgetForm) => {
    console.log(payload);
    sendRequest(
      {
        method: 'POST',
        url: `auth/generate-reset-password-link`,
        payload: {
          email: payload.email,
        },
      },
      (data: any) => {
        notify('Email dispatched! Please check your inbox.', 'success');
        resetForm();
        setIsOpenExistingAccountModal(true);
      },
    );
  };

  return (
    <div
      className=" bg-cover bg-center h-full w-full lg:bg-auth-background sm:bg-auth-background-small bg-none flex justify-center items-center"
    >
      <div className='lg:w-[480px] sm:w-[448px] sm:h-auto h-full w-full bg-[#EFE8DF] rounded-[5px] sm:p-6 p-2.5'>
        <form onSubmit={handleSubmit}>
          <div className="pb-4  sm:px-3 px-0 sm:mt-0 mt-[70px]">
            <>
              <div className="flex flex-col justify-center items-center">
                <IconSvg
                  icon="logoBlack"
                  className="w-[148.78px] h-[36.16px] cursor-pointer"
                  onClick={() => navigate('/')}
                />
                <div className="mt-10 mb-[30px]">
                  <p className="font-bold font-inter text-xl text-[#0F0F0F] leading-[25.76px]">
                    Forgot password?
                  </p>
                </div>
              </div>

              {formInputs.map((sub, i) => (
                <div className={`${i + 1 == formInputs.length ? 'mt-5' : ''}`} key={i}>
                  <InputText
                    id={sub.name}
                    name={sub.name}
                    label={sub.label}
                    placeholder={sub.placeholder}
                    value={values[sub.name as keyof forgetForm]}
                    onChange={onChangeHandler}
                    isDisabled={sub.disabled}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={
                      touched[sub.name as keyof forgetForm] &&
                      !!errors[sub.name as keyof forgetForm]
                    }
                    errorMessage={errors[sub.name as keyof forgetForm]}
                  />
                </div>
              ))}
              <div className=" mt-5">
                <p className="font-light font-inter font sm:text-base text-[10px] sm:leading-[20.35px] leading-3 text-[#4A4A4A]">
                  Enter the email address associated with your account, and we&apos;ll forward
                  instructions for resetting your password. Remember to look in your spam folder
                  too, as our message may be directed there.
                </p>
              </div>
              <div className="sm:mt-5 mt-[30px]">
                <Button
                  size="medium"
                  type="submit"
                  color="yellow"
                  label={
                    <p className="text-[#0F0F0F] font-medium text-base font-inter ">
                      SEND INSTRUCTION
                    </p>
                  }
                  className="w-full"
                />
              </div>
              <p
                className="font-light font-inter  text-base cursor-pointer text-center sm:mt-5 mt-6 leading-[19.2px]"
                onClick={() => navigate('/login')}
              >
                <span className="font-medium font-inter">Back</span>
              </p>
            </>
          </div>
        </form>
        <ExistingAccountInstruction isOpen={isOpenExistingAccountModal} onCancel={onCancel} />
        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
}
