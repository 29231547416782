import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header, IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';

export function TermsOfUse() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const termsOfUse = [
    {
      title: 'Acceptance of the Terms of Use',
      p1: 'This website or mobile application is operated by XPLRS Group LLC-FZ, its subsidiaries, and affiliates (collectively, the “Company," “we," “us," or “our"). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use"), govern your access to and use of our websites, including any content, functionality, and services offered on or through our websites, and our online applications that run on smartphones, tablets, and other devices which provide dedicated non-browser-based interaction between you and our websites (all of which are collectively called our “Website"), whether as a guest or a registered user.',
      p2: 'Please read the Terms of Use carefully before you start to use the Website. By using the Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Website.',
      p3: 'Persons who are under the age of 18 years may only use our Website with legal parental or guardian consent. Accordingly, you agree that you are 18 years of age or older or possess legal parental or guardian consent and are fully able and competent to enter into the terms, conditions, representations, and warranties set forth in these Terms of Use; otherwise, please exit the Website. We suggest that you take advantage of any access controls offered through the Website or third-party sites, which are designed to assist you in limiting or blocking access to certain types of web content you may feel are harmful to or inappropriate for minors.',
    },
    {
      title: 'Membership Subscription',
      p1: '',
      listItems: [
        {
          listLine: 'Types of Memberships',
          listItemPoints: [
            'Annual Membership: Valid for 12 months.',
            'Biannual Membership: Valid for 6 months.',
          ],
        },
        {
          listLine: 'Membership Fees',
          listItemPoints: [
            'The price of a membership may change at any time.',
            'The price at which you purchase the membership applies only to the next 6 or 12 months, based on your membership choice.',
            'During your active membership period:',
            'If prices decrease, refunds are not applicable.',
            'If prices increase, you will not be required to pay the difference.',
          ],
        },
      ],
    },
    {
      title: 'Refund Policy',
      p1: '',
      listItems: [],
      listPoints: [
        'Refunds are available within the first 24 hours of membership purchase unless a booking has been made on the platform.',
        'Memberships purchased with a voucher cannot be refunded.',
      ],
    },
    {
      title: 'Automatic Renewal and Cancellation',
      p1: '',
      listItems: [],
      listPoints: [
        'Memberships automatically renew at the end of the term.',
        'Memberships will renew at the price associated with the type of membership relevant at the time of renewal. This price may be higher than your previous purchase.',
        'You may cancel your membership at any time to avoid automatic renewal.',
      ],
    },
    {
      title: 'Subscription Management',
      p1: '',
      listItems: [],
      listPoints: [
        'Payment will be charged to your account at the confirmation of purchase.',
        'Subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period.',
        'Your account will be charged for renewal within 24 hours prior to the end of the current period, and the cost of the renewal will be identified.',
        'Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user’s Account Settings after purchase.',
        'Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable.',
      ],
    },
    {
      title: 'Service Provision and Responsibility for Participating Properties',
      p1: '',
      listItems: [],
      listPoints: [
        'XPLRS Group aggregates offers from third-party providers and strives to find the best available rates but does not guarantee discounts.',
        'When you make a booking, you agree to pay the cost of the travel experience, including any applicable taxes and charges.',
        'Some prices may be rounded; the price you pay will be based on the original, non-rounded amount.',
        'All deals, including those linked from our website, emailed offers, or displayed through any extensions, are priced and managed by the participating property or third-party service provider.',
        'You agree that all bookings made through a third-party property or service provider are subject to the terms and conditions of the respective merchant. XPLRS Group LLC-FZ is not responsible for any issues or liabilities arising from such bookings.'
      ],
      afterListPointsLine:'For more details, please refer to our'
    },
    {
      title: 'Changes to the Terms of Use',
      p1: '',
      listItems: [],
      listPoints: [
        'We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page each time you access the Website so you are aware of any changes, as they are binding on you.',
      ],
    },
    {
      title: 'Accessing the Website and Account Security',
      p1: '',
      listItems: [],
      listPoints: [
        'We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.',
      ],
      headingsWithPoints: [
        {
          heading: 'You are responsible for:',
          headingPoinst: [
            'By making a booking, you accept the applicable policies displayed during the booking process.',
            'Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.',
            'To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with the Website or otherwise, including but not limited to through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.',
            'If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Website or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.',
            'We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.'
          ],
        },
      ],
    },
    {
      title: 'Intellectual Property Rights',
      p1: 'The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by the Company, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.',
      listItems: [
        {
          listLine: 'These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:',
          listItemPoints: [
            'Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.',
            'You may store files that are automatically cached by your web browser for display enhancement purposes.',
            'You may print one copy of a reasonable number of pages of the Website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.',
            'If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end-user license agreement for such applications.',
            'If we provide social media features with certain content, you may take such actions as are enabled by such features.',
          ],
        },
        {
          listLine: 'You must not:',
          listItemPoints: [
            'Modify copies of any materials from the Website.',
            'Use any illustrations, photographs, video, or audio sequences or any graphics separately from the accompanying text.',
            'Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Website.',
            'Access or use for any commercial purposes any part of the Website or any services or materials available through the Website.',
          ],
        },
      ],
      p2:'If you wish to make any use of material on the Website other than that set out in this section, please address your request to hi@xplorius.com.',
      p3:'If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.'
    },
    {
      title: 'Trademarks',
      p1: 'The Company name and trademarks, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on the Website are the trademarks of their respective owners.',
    },
    {
      title: 'Governing Law and Jurisdiction',
      p1: 'These Terms of Sale and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of Dubai, UAE without giving effect to any choice or conflict of law provision or rule (whether of Dubai, UAE or any other jurisdiction).',
      p2: 'Any legal suit, action, or proceeding arising out of, or related to, these Terms of Sale shall be instituted exclusively in the courts of Dubai, UAE, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Sale in your jurisdiction of residence or any other relevant jurisdiction. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.',
    },
    {
      title: 'Entire Agreement',
      p1: 'These Terms of Sale, along with our Terms of Use, Privacy Policy and Cookie Policy, constitute the entire and exclusive agreement between you and XPLRS Group LLC-FZ regarding your purchase of the product. This agreement supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, related to your purchase.',
    },
  ];

  return (
    <>
      <div className="w-full bg-[#EFE8DF] ">
        <Header />
        <div className="lg:px-10 px-2.5">
          <div className="sm:flex items-center justify-between mt-[72px]">
            <div className="flex items-center gap-2.5">
              <IconSvg icon="horizontalDoubleHeadLongArrow" className="lg:w-[152px] w-[77px] inline-block" />
              <h1 className="font-inter font-medium lg:text-6xl sm:text-3xl text-2xl lg:leading-[72px] sm:leading-9 leading-[28.8px] text-[#0F0F0F]">
                TERMS OF USE
              </h1>
            </div>
            <p className="font-light font-inter lg:text-2xl sm:text-sm text-[12px] lg:leading-[28.8px] sm:leading-[16.8px] leading-[14.4px] sm:pt-0 pt-3">
              <span className="font-inter font-bold text-[#0F0F0F]">Last modified:</span> 1
              June 2024
            </p>
          </div>

          <div className="mt-[70x] lg:w-[861px] w-full">
            {termsOfUse.map((term, i) => (
              <>
                <div className="mt-[34px]">
                  <h4 className="font-inter font-bold lg:text-2xl sm:text-xl text-lg lg:leading-[28.8px] sm:leading-6 leading-[21.6px] text-[#0F0F0F]">
                    {term.title}
                  </h4>
                  {term.p1 && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p1}
                    </p>
                  )}
                  {term?.listItems &&
                    term?.listItems?.length >= 1 &&
                    term?.listItems?.map((listItem, i) => (
                      <>
                        <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A] mt-[34px]">
                          {listItem.listLine}
                        </p>
                        <ul className="list-disc pl-[29px]">
                          {listItem.listItemPoints.map((point, pointIndex) => (
                            <li
                              className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                              key={pointIndex}
                            >
                              {point}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  {term?.listPoints && (
                    <ul className="list-disc pl-[29px] mt-5">
                      {term?.listPoints?.length >= 1 &&
                        term.listPoints.map((listPoint, listPointIndex) => (
                          //   <div className="flex items-start" key={listPoint + listPointIndex}>
                          //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                          <li
                            className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                            key={listPoint + listPointIndex}
                          >
                            {listPoint}
                          </li>
                          //   </div>
                        ))}
                    </ul>
                  )}
                  {term.afterListPointsLine&& <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]">{term.afterListPointsLine}  <span className="font-inter font-bold text-[#C56B48] border-b inline-block border-[#C56B48] cursor-pointer " onClick={() => navigate("/")}>Terms of Sale.</span></p>}
                  {term?.headingsWithPoints &&
                    term?.headingsWithPoints?.length >= 1 &&
                    term.headingsWithPoints.map((heading, headingIndex) => (
                      <>
                        <h6 className="font-bold lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] font-inter text-[#4A4A4A] pt-[24px]">
                          {heading.heading}
                        </h6>
                        {
                          <ul className="list-disc pl-[29px]">
                            {heading.headingPoinst.map((headingPoint, headingPointIndex) => (
                              //   <div className="flex items-start " key={headingPoint + headingPointIndex}>
                              //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                              <li
                                className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                                key={headingPoint + headingPointIndex}
                              >
                                {headingPoint}
                              </li>
                              //   </div>
                            ))}
                          </ul>
                        }
                      </>
                    ))}
                  {term.p2 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p2}
                    </p>
                  )}
                  {term.p3 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p3}
                    </p>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="lg:mt-[204px] sm:mt-[30px] mt-6 w-full">
          <div className="border-b border-[#4A4A4A]"></div>
          <div className="sm:mt-[46px] mt-6 lg:pb-[46px] lg:px-10 px-[15.5px]">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
