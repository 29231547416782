import React, { useState } from 'react';
// import { IconSvg } from '../IconSvg';
import styles from './UploadImage.module.scss';
import { IconSvg } from '../IconSvg';

interface Props {
  accept: string;
  mbSize: number;
  onFileDropSuccess: (file: File) => void;
  iconBtn?: JSX.Element;
  label?: string;
}

export function UploadImage({ accept, mbSize, onFileDropSuccess, iconBtn, label }: Props) {
  const [fileError, setFileError] = useState('');

  const validateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileError('');
    if (event.target.files) {
      const fileSize = event.target.files[0].size / 1024 / 1024; // in MiB

      if (event.target.files.length > mbSize) {
        setFileError('Too much files');
      } else if (fileSize > 1) {
        setFileError(`File size exceeds ${mbSize} MB`);
      } else {
        onFileDropSuccess(event.target.files[0]);
      }
    }
  };

  return (
    <div className={styles['upload-img']}>
      {iconBtn ? (
        <label htmlFor="file" className="btn">
          {iconBtn} {label}
        </label>
      ) : (
        <label htmlFor="file" className="btn flex justify-center">
          {/* <IconSvg icon="imageUpload" className='relative z-[-1]'/> */}
          {/* <span className='font-normal text-sm font-inter text-black'>Click to upload</span> */}
          <>
            <div className="flex flex-col justify-center items-center pt-4">
              <IconSvg icon="uploadFile" />
              <p className="font-bold sm:text-xl text-[12px] font-inter mt-4 text-[#3E7A63]">
                Add file{' '}
                <span className="text-[#0F0F0F] font-medium sm:text-lg text-[12px]">or drop files here</span>{' '}
              </p>
            </div>
            {/* <p className="font-inter font-bold text-[15px]">Logo</p>

          <div className={styles['validation-rules']}>
            <p className="font-normal font-inter text-sm">JPG, PNG, up to 1MB</p>
          </div> */}
          </>
        </label>
      )}

      <input onChange={event => validateInput(event)} id="file" type="file" accept={accept}></input>
      {fileError.length > 0 && <p className={styles['error']}>{fileError}</p>}
    </div>
  );
}
