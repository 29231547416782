import React from 'react';
import clsx from 'clsx';
import styles from './TextArea.module.scss';

interface Props {
  id: string;
  name: string;
  value: string;
  onChange: (input: { name: string; value: string }) => void;

  rows?: number;
  label?: string;
  tagline?: boolean;
  taglineText?: string;
  wrapperStyles?: string;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onBlur?: (input: { name: string; value: string }) => void;
}

export function TextArea({
  id,
  name,
  value,
  onChange,
  rows = 5,
  label,
  wrapperStyles,
  isError,
  errorMessage,
  placeholder,
  tagline,
  taglineText,
  onBlur,
}: Props) {
  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!onBlur) return;

    const { name, value } = event.target;
    onBlur({ name, value });
  };

  return (
    <div className={`${wrapperStyles} flex flex-col`}>
      {label && (
        <label className={styles['label']} htmlFor={name}>
          {label}
        </label>
      )}

      {tagline && (
        <p className="text-sm text-[#000000A3] font-normal font-inter md:mb-2">{taglineText}</p>
      )}

      <textarea
        className={clsx(styles['textarea'], isError && styles['input-invalid'])}
        id={id}
        name={name}
        value={value}
        onChange={onChangeHandler}
        rows={rows}
        placeholder={placeholder}
        onBlur={onBlurHandler}
        autoComplete={'off'}
      />
      {isError && <p className={styles['error-text']}>{errorMessage}</p>}
    </div>
  );
}
