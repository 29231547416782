import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';
import ProtectedRoute from './ProtectedRoute';
import { AboutUs, CookiePolicy, HomePage, ManagePlans, PrivacyPolicy, TermsOfSale, TermsOfUse } from 'src/pages/public';
import { ForgetPassword, Login, ResetPassword, Signup, ValidateResetLink } from 'src/pages/public/authentication';
import { Billing, Booking, Coupon, CreateCoupon, Help, Profile, UpdateCoupon } from 'src/pages/protected';
import ProtectedRoutePolicy from './ProtectedRoutePolicy';
import XploriusLayoutPage from 'src/pages/XploriusLayoutPage';

export default function AppRouter() {
  //it's still empty when we try to check if user is logged in to give access to pages,
  //there is delay, and we can't access to pages without token
  const { profile } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="" element={<HomePage />} />

      <Route path="/profile" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="" element={<Profile />} />
      </Route>

      <Route path="" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="help" element={<Help />} />
      </Route>

      <Route path="" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="coupons" element={<Coupon />} />
      </Route>

      <Route path="" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="create-coupon" element={<CreateCoupon />} />
      </Route>
      <Route path="" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="update-coupon/:id" element={<UpdateCoupon />} />
      </Route>

      <Route path="" element={<ProtectedRoutePolicy template={<XploriusLayoutPage />} />}>
        <Route path="/billing" element={<Billing />} />
      </Route>

      <Route path="/booking" element={<ProtectedRoutePolicy template={<Booking />} />} />

      <Route path="/">
        <Route>
          <Route path="" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-sale" element={<TermsOfSale />} />
          <Route path="/terms-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />

          {/*<Route path="/home" element={<HomePage />} />*/}
          <Route path="/sign-up" element={<ManagePlans />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/validate-reset-password" element={<ValidateResetLink />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute isAccess={!profile} />}>
        {/* <Route path="/sign-up/:accountType" element={<Signup />} /> */}
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
