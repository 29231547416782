import { useContext, useEffect, useState } from 'react';
import { Button, IconSvg } from 'src/components/common';
import {
  PersonalDetails,
  Plans,
  PlansSelectComponent,
  VoucherDetails,
} from 'src/components/manage-plans';
import { useHttp, useNotification } from 'src/hooks';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts';
import { setToLocalStorage } from '../../../utils';

interface PersonalDetailsForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
interface voucherForm {
  voucherNo: string;
  // address: string;
  // postalCode: string;
  // city: string;
  // state: string;
}
export function ManagePlans() {
  const notify = useNotification();
  const navigate = useNavigate();
  const {
    accessToken,
    appConfig,
    fetchProfile,
    isFetchingProfile,
    profile,
    fetchAppConfig,
    loginWithRedirect,
    setAccessToken,
  } = useContext(AuthContext);
  const { sendRequest: apiRequestHandler, isLoading: isLoading, error } = useHttp();
  const [couponDiscount, setCouponDiscount] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');
  const [paymentIntentLoading, setPaymentIntentLoading] = useState(false);
  const { sendRequest, isLoading: isLoadingIntent, error: sendRequestError } = useHttp();
  const [currentComponent, setCurrentComponent] = useState(1);
  const [finalData, setFinalData] = useState<any>();
  const [currentPlanName, setCurrentPlanName] = useState<string>('');
  const [plansDropdownValue, setPlansDropdownValue] = useState<{
    label: string;
    value: string;
    amount: number;
  }>({ label: '', value: '', amount: 0 });
  const numberArray = [];
  for (let i = 1; i <= 3; i++) {
    numberArray.push(i);
  }
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const setup_intent = params.get('setup_intent');
  const setup_intent_client_secret = params.get('setup_intent_client_secret');
  const redirect_status = params.get('redirect_status');
  const coupon = params.get('coupon');
  const step = params.get('step');
  const [prices, setPrices] = useState<any>([]);

  const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  if (!stripeKey) {
    throw new Error('Stripe public key is not defined');
  }
  const setDefaultPaymentMethod = () => {
    sendRequest(
      {
        url: `payment/set-default-payment-method/${setup_intent}`,
        method: 'POST',
        payload: { setDefault: 'true', coupon },
        accessToken,
      },
      (response: any) => {
        fetchAppConfig();
        notify(`${response.message}`, 'success');
        // const urlWithoutParams = window.location.origin + window.location.pathname;
        // window.history.replaceState(null, '', urlWithoutParams);
        // navigate('/booking');
      },
    );
  };
  useEffect(() => {
    if (redirect_status === 'succeeded' && accessToken) {
      setDefaultPaymentMethod();
    }
  }, [setup_intent, setup_intent_client_secret, redirect_status, accessToken]);

  const personalDetailsSubmitHandler = (payload: PersonalDetailsForm) => {
    signup(payload);
    return;
    // getIntent(payload);

    // apiRequestHandler(
    //   {
    //     url: `user/sign-up`,
    //     method: 'POST',
    //     payload: payload,
    //   },
    //   (response: any) => {
    //     console.log({ response });
    //     getIntent(payload.email);
    //     setCurrentComponent(prev => prev + 1);
    //     setFinalData((prev: any) => ({ ...prev, ...payload }));
    //   },
    // );
  };

  const signup = (payload: PersonalDetailsForm) => {
    setPaymentIntentLoading(true);
    apiRequestHandler(
      {
        method: 'POST',
        url: `auth/signup`,
        accessToken,
        payload: {
          email: payload.email,
          password: payload.password,
          firstName: payload.firstName,
          lastName: payload.lastName,
          priceId: finalData?.planId,
        },
      },
      (data: any) => {
        setToLocalStorage(`::@@xploriusJwtAuthspajs@@::`, data);
        setAccessToken(data.accessToken);
        setFinalData((prev: any) => ({ ...prev, ...payload }));
      },
    );
  };

  useEffect(() => {
    accessToken && appConfig && !appConfig?.isApproved && !clientSecret && getIntent();
  }, [accessToken, appConfig]);

  useEffect(() => {
    if (appConfig && !appConfig.isApproved && !setup_intent) {
      navigate('/sign-up?step=3');
    }
  }, [appConfig, setup_intent]);

  useEffect(() => {
    if (step && !setup_intent && appConfig) {
      setCurrentComponent(parseInt(step) - 1);
    }
  }, [step, setup_intent, appConfig]);

  const createSubscription = (priceId: string, email: string) => {
    apiRequestHandler(
      {
        url: `payment/create-subscription`,
        method: 'POST',
        payload: { priceId: priceId, email },
        // accessToken,
      },
      (response: any) => {
        setCurrentComponent(prev => (prev == 3 ? 1 : prev + 1));
      },
    );
  };
  const voucherDetailsSubmitHandler = (payload: voucherForm) => {
    // createSubscription(finalData?.planId, finalData?.email);
  };

  const onAddCouponHandler = (payload: { value: string; type: string }) => {
    console.log(payload, 'this sets the coupon');

    setCouponDiscount(payload.value);
    setDiscountType(payload.type);
  };
  const planSubmitHandler = (
    id: string,
    amount: string | number,
    title: string,
    withoutPriceId: string,
  ) => {
    setFinalData((prev: any) => ({
      ...prev,
      planId: id,
      planAmount: amount,
      planTitle: title,
      withoutPriceId,
    }));
    setPlansDropdownValue({ label: title, value: withoutPriceId, amount: +amount });
  };

  useEffect(() => {
    if (error || sendRequestError) {
      notify(error || sendRequestError || '', 'error');
      setPaymentIntentLoading(false);
    }
  }, [error, sendRequestError]);

  useEffect(() => {
    if (profile && !finalData?.planId) {
      const payload = {
        planId: profile.account.plan.priceId,
        planAmount: parseInt(profile.account.plan.amount),
        planTitle: profile.account.plan.name,
        withoutPriceId: profile.account.plan.id,
      };
      setFinalData(payload);
      setPlansDropdownValue({
        label: profile.account.plan.name,
        value: profile.account.plan.id,
        amount: +profile.account.plan.amount,
      });
    }
  }, [profile]);

  const getIntent = () => {
    setPaymentIntentLoading(true);
    apiRequestHandler(
      {
        method: 'POST',
        url: `payment/payment-method-intent`,
        accessToken,
        payload: {},
      },
      (intent: any) => {
        setClientSecret(intent.secret);
        setPaymentIntentLoading(false);
        setCurrentComponent(prev => prev + 1);
      },
    );
  };

  const appearance = {
    variables: {
      colorPrimary: '#000000',
      colorBackground: '#EFE8DF',
      colorText: '#000000',
      colorDanger: '#df1b41',
      spacingUnit: '2px',
      borderRadius: '5px',
    },
    rules: {
      '.Input': {
        borderColor: '#959595',
        color: '#4A4A4A',
        padding: '8px 12px',
      },
      '.Input:focus': {
        borderColor: '#959595',
      },
      '.Label': {
        color: '#4A4A4A',
        fontFamily: "'Inter'",
        fontSize: '14px',
        marginBottom: '8px',
        marginTop: '12px',
        lineHeight: '24px',
        fontWeight: '500',
      },
    },
  };
  const stripePromise = loadStripe(stripeKey);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance,
  };

  // useEffect(() => {
  //   if (accessToken) {
  //     createNewUser();
  //   }
  // }, [accessToken]);
  // const createNewUser = async () => {
  //   await sendRequest(
  //     {
  //       url: 'user',
  //       method: 'POST',
  //       accessToken,
  //     },
  //     (data: any) => {
  //       fetchProfile();
  //     },
  //   );
  // };

  useEffect(() => {
    if (appConfig && appConfig.isApproved) {
      navigate('/booking');
    }
  }, [appConfig]);

  const onChangeHandler = ({
    name,
    value,
    amount,
  }: {
    name: string;
    value: string;
    amount: number;
  }) => {
    setPlansDropdownValue({ label: name, value, amount });
    updatePlansHandler(value);
  };

  const getCompanyPaymentMethods = () => {
    sendRequest(
      {
        url: 'payment/prices',
        method: 'GET',
      },
      (data: any) => {
        setPrices(data);
      },
    );
  };

  useEffect(() => {
    getCompanyPaymentMethods();
  }, []);

  const plansOptions = [
    {
      label: 'BIANNUAL',
      value: prices[0]?.id,
      amount: prices[0]?.amount ? parseInt(prices[0]?.amount, 10) : 0,
    },
    {
      label: 'YEARLY',
      value: prices[1]?.id,
      amount: prices[1]?.amount ? parseInt(prices[1]?.amount, 10) : 0,
    },
  ];

  const updatePlansHandler = (planId: string) => {
    sendRequest(
      {
        url: `payment/update-user-plan/${planId}`,
        method: 'PATCH',
        accessToken,
      },
      (data: any) => {
        fetchProfile();
        notify(data?.message, 'success');
        setCurrentPlanName(plansDropdownValue.label);
      },
    );
  };

  console.log({ plansDropdownValue });

  return (
    <>
      <div className="flex justify-center bg-[#EFE8DF] w-full h-full overflow-auto">
        {setup_intent ? (
          <>
            <div className="w-[519px]">
              <div className="flex items-center justify-between w-full mt-[77px]">
                <IconSvg
                  icon="logoBlack"
                  className="w-[149px] cursor-pointer"
                  onClick={() => navigate('/')}
                />
              </div>

              <div className="flex w-full gap-2 my-8">
                {[1, 2, 3].map(num => (
                  <div
                    key={`progress-${num}`}
                    className={` ${
                      currentComponent == 1
                        ? 'w-[128px]'
                        : 'lg:grow lg:w-0 sm:w-[128px] sm:grow-0 grow'
                    }  h-2.5 ${`bg-[#C56B48]`} `}
                  ></div>
                ))}
              </div>
              <h3 className="text-xl font-inter font-bold text-[#0F0F0F] leading-6 ">
                Thank you for the patience.
              </h3>
              <h6 className="text-base font-light mt-4 text-[#4A4A4A] font-inter">
                We are setting up and you will be redirected shortly..
              </h6>
            </div>
          </>
        ) : (
          <div
            className={`${
              currentComponent == 1
                ? 'lg:w-[832px] sm:w-[473px] w-full  sm:px-0 px-2.5'
                : 'lg:w-[519px] sm:w-[473px] w-full  sm:px-0 px-2.5 h-screen'
            }`}
          >
            {currentComponent == 2 && (
              <div className="sm:mt-[60px] mt-5 mb-5">
                <IconSvg icon="arrowBack" className="w-6" onClick={() => setCurrentComponent(1)} />
              </div>
            )}
            <div
              className={`flex items-center justify-between w-full ${
                currentComponent === 2 ? '' : 'sm:mt-[77px] mt-5'
              }`}
            >
              <IconSvg
                icon="logoBlack"
                className="w-[149px] cursor-pointer"
                onClick={() => navigate('/')}
              />
              {currentComponent !== 3 && (
                <p
                  className="uppercase font-medium text-lg font-inter leading-[21.6px] text-[#4C4C4C] border-b border-[#4C4C4C] inline-block cursor-pointer"
                  onClick={() => navigate('/login')}
                >
                  {' '}
                  sign in
                </p>
              )}
            </div>

            <div className="flex w-full gap-2 my-8">
              {numberArray.map(num => (
                <div
                  key={`progress-${num}`}
                  className={` ${
                    currentComponent == 1
                      ? 'w-[128px]'
                      : 'lg:grow lg:w-0 sm:w-[128px] sm:grow-0 grow'
                  }  h-2.5 ${currentComponent >= num ? `bg-[#C56B48]` : `bg-[#CCCCCC]`} `}
                ></div>
              ))}
            </div>

            {currentComponent == 2 && finalData?.planTitle && (
              <div className="flex items-center justify-between border border-[#4A4A4A] rounded-[5px] sm:py-4 sm:px-4 py-[10.5px] px-3 mb-8">
                <p className="font-light sm:text-xl text-base font-inter text-[#4A4A4A]">
                  {finalData?.planTitle}
                </p>
                <p className="font-bold sm:text-xl text-base font-inter text-[#0F0F0F] inline-flex items-center justify-center gap-2.5  mt-0">
                  {couponDiscount && couponDiscount !== 'undefined'
                    ? discountType === 'percent'
                      ? (
                          finalData?.planAmount -
                          (parseInt(couponDiscount) * finalData?.planAmount) / 100
                        ).toFixed(2)
                      : (finalData?.planAmount - parseInt(couponDiscount)).toFixed(2)
                    : finalData?.planAmount}{' '}
                  USD
                  {couponDiscount && couponDiscount !== 'undefined' ? (
                    <span className="bg-[#4A4A4A] px-[6.9px] py-[4.5px] text-[#EFE8DF] rounded-[6.9px] text-[11.03px] leading-[13.24px]">
                      (SAVED{' '}
                      {discountType === 'percent' ? `${couponDiscount}%` : `${couponDiscount} USD`})
                    </span>
                  ) : null}
                </p>
              </div>
            )}
            {currentComponent == 3 && finalData?.planTitle && (
              <div className="mb-8">
                <PlansSelectComponent
                  id={'plans'}
                  name={'plans'}
                  label={''}
                  placeholder={''}
                  options={plansOptions}
                  // value={plansOptions.find(c => c.value === plansDropdownValue?.value)}
                  value={{
                    label: profile?.account.plan.name,
                    value: profile?.account.plan.id,
                    amount: +profile?.account.plan.amount,
                  }}
                  onChange={onChangeHandler}
                  // isError={touched["plans"] && !!errors["plans"]}
                  // errorMessage={errors["plans"]}
                  // indicatorValue={plansDropdownValue?.amount + ' USD'}
                  indicatorValue={
                    couponDiscount && couponDiscount !== 'undefined'
                      ? discountType === 'percent'
                        ? (
                            profile?.account.plan.amount -
                            (parseInt(couponDiscount) * profile?.account.plan.amount) / 100
                          ).toFixed(2) + ' USD'
                        : (profile?.account.plan.amount - parseInt(couponDiscount)).toFixed(2) +
                          ' USD'
                      : profile?.account.plan.amount + ' USD'
                  }
                  couponDiscount={couponDiscount}
                />
              </div>
            )}

            {currentComponent == 1 && (
              <>
                {' '}
                <Plans onSubmitHandler={planSubmitHandler} />
                <div className="lg:pb-0 sm:pb-[88px] pb-[45px]">
                  <Button
                    size="small"
                    color="primary"
                    label={
                      <p className="uppercase text-[#EFE8DF] font-medium font-inter text-base leading-[21.6px]">
                        Continue
                      </p>
                    }
                    type="submit"
                    className="w-full mt-[21px]"
                    isDisabled={!finalData?.planId}
                    onClick={() => {
                      if (finalData?.planId) setCurrentComponent(prev => prev + 1);
                    }}
                  />
                </div>
              </>
            )}
            {currentComponent == 2 && (
              <>
                {' '}
                <PersonalDetails onSubmitHandler={personalDetailsSubmitHandler} planId={finalData?.planId} />
              </>
            )}
            {currentComponent == 3 && (
              <>
                {' '}
                {clientSecret ? (
                  <Elements stripe={stripePromise} options={options}>
                    <VoucherDetails
                      setCouponDiscount={setCouponDiscount}
                      onSubmitHandler={voucherDetailsSubmitHandler}
                      onAddCouponHandler={onAddCouponHandler}
                      finalData={finalData}
                      planName={currentPlanName}
                    />
                  </Elements>
                ) : null}
              </>
            )}
          </div>
        )}
      </div>
      {(paymentIntentLoading ||
        isLoading ||
        setup_intent ||
        isLoadingIntent ||
        isFetchingProfile) && <LoadingSpinner />}
    </>
  );
}
