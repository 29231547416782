import { useMemo } from 'react';
import styles from './CompanyAvatar.module.scss';

interface Props {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  url: string;
  isCursorPointer?: boolean;
  onClick?: () => void;
  wrapperStyles?: string;
}

export function CompanyAvatar({ size, url, isCursorPointer, onClick, wrapperStyles }: Props) {
  const styleSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return styles['company-avatar-xs'];

      case 'sm':
        return styles['company-avatar-sm'];

      case 'md':
        return styles['company-avatar-md'];

      case 'lg':
        return styles['company-avatar-lg'];

      case 'xl':
        return styles['company-avatar-xl'];

      default:
        return styles['company-avatar-2xl'];
    }
  }, [size]);

  return (
    <div className={wrapperStyles}>
      <img
        src={url}
        style={isCursorPointer ? { cursor: 'pointer' } : {}}
        alt=""
        className={styleSize}
        onClick={onClick}
      />
    </div>
  );
}
