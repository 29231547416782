import { Button, IconSvg, Modal } from 'src/components/common';

export interface forgetForm {
  email: string;
}
interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

export function ExistingAccountInstruction({ isOpen, onCancel }: Props) {
  return (
    <Modal close={onCancel} isOpen={isOpen} loading={false} modalWidth="sm:w-[608px] w-full">
      <>
        <div className="lg:pb-4 sm:pb-0  lg:px-[6px] px-0">
          <>
            <div className="flex items-start sm:gap-4 gap-3">
              <div>
                <p className="font-light font-inter sm:text-base text-[12px] text-[#4A4A4A] sm:leading-[19.2px] leading-[14.4px]">
                  If the address you entered corresponds to an existing account, we&apos;ll dispatch
                  an email to it with password reset instructions.
                </p>
                <p className="font-light font-inter sm:text-base text-[12px] text-[#4A4A4A] mt-2 sm:leading-[19.2px] leading-[14.4px]">
                  In case your account was created using Google or Microsoft without setting a
                  separate password on Typeform, consider signing in using the initial method used
                  for account creation.
                </p>
              </div>
              <IconSvg icon="cross" className='sm:w-[20.8px] sm:h-[21.31px] w-[12px] h-[12.29px] cursor-pointer' onClick={onCancel} />
            </div>
            <div className="sm:flex sm:flex-row flex-col sm:justify-between sm:items-center inline-block sm:mt-[32.5px] mt-5">
              <p className="font-medium font-inter lg:text-lg sm:text-sm text-[12px] text-[#0F0F0F]">
                Encountering further difficulties?
              </p>
              <p className="sm:font-light font-bold font-inter lg:text-base sm:text-sm text-[12px]  sm:text-[#0F0F0F] text-[#C56B48] border-b inline-block sm:border-b-[#0F0F0F] border-b-[#C56B48] sm:pt-0 pt-1.5 !mt-0">
                We&apos;re here to assist you.
              </p>
            </div>
            <div className="sm:mt-[32px] mt-5">
              <Button
                size="medium"
                type="submit"
                color="yellow"
                label={<p className="text-[#0F0F0F] font-medium sm:text-base text-sm font-inter ">OKAY</p>}
                className="w-full"
                onClick={onCancel}
              />
            </div>
          </>
        </div>
      </>
    </Modal>
  );
}
