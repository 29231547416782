import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useHttp, useNotification } from '../../../hooks';

import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';

export function ValidateResetLink() {
  const { sendRequest: apiRequestHandler, isLoading: isLoading, error } = useHttp();
  const location = useLocation();
  const notify = useNotification();
  const navigate = useNavigate();

  const validateResetPassLink = () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    apiRequestHandler(
      {
        method: 'POST',
        url: `auth/validate-reset-password-link`,
        payload: {
          encryptedData: token,
        },
      },
      (data: any) => {
        navigate(`/reset-password?token=${token}`);
      },
    );
  };
  useEffect(() => {
    if (error) {
      notify(error || '', 'error');
    }
  }, [error]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    validateResetPassLink();
  }, [location.search]);

  return <>{isLoading && <LoadingSpinner />}</>;
}
