import { Button, IconSvg, Modal } from 'src/components/common';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

export function HelpConfirmationModal({ isOpen, onCancel }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      close={onCancel}
      loading={false}
      modalWidth="lg:w-[978px]  lg:h-[336px] sm:h-[298px] sm:w-[600px] w-full !p-0 !m-0"
    >
      <>
        <div className="lg:block hidden">
          <>
            <div className="flex items-start gap-2 w-full">
              <div className="w-full pb-4  px-9 pt-5">
                <p className="font-bold text-2xl font-inter text-[#0F0F0F]">
                  Thank you for reaching out!
                </p>
                <div className="">
                  <p className="font-light text-base font-inter text-[#4A4A4A] pt-4">
                    Your inquiry has been successfully submitted. We&apos;re diligently reviewing
                    your message and will respond promptly.
                  </p>
                  <p className="font-light text-base font-inter text-[#4A4A4A] mt-3">
                    Please check your inbox and, just in case, your junk/
                    <br />
                    spam folder for our reply. For immediate assistance,
                    <br /> explore our FAQ section.
                  </p>
                  <div className="flex items-center gap-4 mt-4">
                    <Button
                      size="small"
                      color="primary"
                      type="submit"
                      label={
                        <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                          Done
                        </p>
                      }
                      className=""
                      onClick={onCancel}
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-full flex items-start">
                <IconSvg icon="helpRoundedLines" />
                <div className="absolute right-6 top-6">
                  <IconSvg icon="cross" className='cursor-pointer' onClick={onCancel} />
                </div>
              </div>
            </div>
          </>
        </div>

        {/* This section for tablet start */}
        <div className="lg:hidden sm:block hidden h-full">
          <>
            <div className="flex justify-between items-center px-[30px] pt-[30px]">
              <p className="font-bold text-xl leading-6 font-inter text-[#0F0F0F]">
                Thank you for reaching out!
              </p>
              <div className="">
                <IconSvg icon="cross" className='cursor-pointer' onClick={onCancel} />
              </div>
            </div>
            <div className="flex justify-between items-start pl-[30px] mt-[21px] h-full">
              <div className=" w-[437px]">
                <p className="font-  text-base leading-[19.2px] font-inter text-[#4A4A4A] w-full">
                  Your inquiry has been successfully submitted. We&apos;re diligently reviewing your
                  message and will respond promptly.
                </p>
                <p className="font-light text-base leading-[19.2px] font-inter text-[#4A4A4A] w-full !mt-0 pt-5">
                  Please check your inbox and, just in case, your junk/
                  <br />
                  spam folder for our reply. For immediate assistance,
                  <br /> explore our FAQ section.
                </p>
                <div className="flex items-center gap-4 mt-[21px]">
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                    Done
                  </p>
                }
                className=""
                onClick={onCancel}
              />
            </div>
              </div>
              <div style={{ paddingTop:'13px' }}>
                <IconSvg icon="helpRoundedLinesForTablet" />
              </div>
            </div>
            
          </>
        </div>
        {/* This section for tablet end */}

        {/* This section for mobile start */}

        <div className="sm:hidden block relative">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <p className="font-bold  text-sm font-inter text-[#0F0F0F]">
                Thank you for reaching out!
              </p>
              <div className="absolute right-6 top-6">
                <IconSvg icon="cross" className="w-[12px] h-[12.29px] cursor-pointer" onClick={onCancel} />
              </div>
            </div>
            <div className="mt-[21px]">
              <p className="font-light text-[12px] font-inter text-[#4A4A4A]">
                Your inquiry has been successfully submitted. We&apos;re diligently reviewing your
                message and will respond promptly.
              </p>
              <p className="font-light text-[12px] font-inter text-[#4A4A4A] mt-5">
                Please check your inbox and, just in case, your junk/ spam folder for our reply. For
                immediate assistance, explore our FAQ section.
              </p>
            </div>
            <div className="">
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter text-[12px] leading-[14.4px]">
                    Done
                  </p>
                }
                className="w-[60px] h-[33px] mt-[21px]"
                onClick={onCancel}
              />
            </div>
          </div>
          <div className="absolute bottom-0 right-0">
            <IconSvg icon="helpRoundedLinesMobile" />
          </div>
        </div>
        {/* This section for mobile end */}
      </>
    </Modal>
  );
}
