import { Button, IconSvg, Modal } from 'src/components/common';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmitHandler: (id:string) => void;
  id: string
}

export function CouponConfirmationModal({ isOpen, onCancel, onSubmitHandler, id }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      close={onCancel}
      loading={false}
      modalWidth="sm:w-[691px] sm:h-[285px] w-full h-[152px] !p-0 !m-0"
    >
      <>
        <div className="sm:pb-4 pb-4  sm:px-9 px-4 sm:pt-5 pt-4">
          <>
            <div className="flex justify-between sm:items-start items-center gap-4 w-full">
              <p className="font-bold sm:text-2xl text-sm font-inter text-[#0F0F0F]">
                Delete Confirmation
              </p>

              <IconSvg icon="cross" className='sm:w-auto sm:h-auto w-[12px] h-[12.29px] cursor-pointer' onClick={onCancel}/>
            </div>
          </>
        </div>
        <div className="relative sm:block hidden">
          <div className="w-[400px] pl-8">
            <p className="font-light text-base font-inter text-[#4A4A4A] ">
              Are you sure you want to delete the coupon?
            </p>
            <div className='flex items-center gap-4 mt-4'>
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                    Yes
                  </p>
                }
                className={"w-full"}
                onClick={() => onSubmitHandler(id)}
              />
              <Button
                size="small"
                color="primary"
                label={
                  <p className="uppercase text-[#4A4A4A] font-medium font-inter text-lg leading-[21.6px]">
                    Cancel
                  </p>
                }
                className="w-full !bg-transparent border rounded-[5px] border-[#4A4A4A]"
                onClick={onCancel}
              />
            </div>
          </div>
          <div className="absolute top-0 right-0 -z-10">
            <IconSvg icon="randomLinesBgGreen" />
          </div>
        </div>

        {/* This section for mobile start */}
        <div className="sm:hidden block px-4">
          <div className="">
            <p className="font-light sm:text-base text-[12px] font-inter text-[#4A4A4A] ">
            Are you sure you want to delete the coupon?
            </p>
            <div className='flex items-center gap-4 mt-4'>
              <Button
                size="small"
                color="primary"
                label={
                  <p className="uppercase text-[#4A4A4A] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Cancel
                  </p>
                }
                className="w-full !bg-transparent border rounded-[5px] border-[#4A4A4A] h-[33px]"
                onClick={onCancel}
              />
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Yes
                  </p>
                }
                className={"w-full h-[33px]"}
                onClick={() => onSubmitHandler(id)}
              />
            </div>
          </div>
        </div>
        {/* This section for mobile end */}

      </>
    </Modal>
  );
}
