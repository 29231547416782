import { useLocation, useNavigate } from 'react-router-dom';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { MenuCard } from './MenuCard';
import { Button, IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';
import { useHttp } from 'src/hooks';
import { signOut } from 'src/utils/cookieUtils';

interface Options {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}
export function Header() {
  const { fetchProfile, logout, loginWithRedirect, appConfig, userAuth0, accessToken, profile, customLogoutHandler } =
    useContext(AuthContext);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const signupHandler = () => {
    void loginWithRedirect({
      appState: { returnTo: `/sign-up` },
      screen_hint: 'signup',
    });
  };

  // const onChangeMenuMember = (value: string, name: string) => {
  //   logout();
  // };
  const onChangeMenuMember = (value: string, name: string, path: string) => {
    if (value == 'My trips') {
      window.open(path, '_blank');
      return;
    }
    if (value == 'Sign out') {
      // logout();
      customLogoutHandler()
      return;
    }
    navigate(path);
  };

  const [myTripUrl, setMyTripUrl] = useState<string>('');

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const optionsData: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCopperRed',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('booking'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowCopperRed',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCopperRed',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  if (appConfig?.isAdmin) {
    const newOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/coupons',
    };
    optionsData.splice(optionsData.length - 1, 0, newOption);
  }

  return (
    <>
      <div className="lg:border-b lg:border-[#4A4A4A] h-[85px] lg:block hidden">
        <div className="flex items-center justify-between w-full h-full">
          <div className=" flex items-center justify-center rounded">
            <IconSvg icon="logoBlack" onClick={() => navigate('/')} className="w-44 cursor-pointer" />
          </div>
          <div className="flex items-center gap-6">
            <div className="flex items-baseline gap-8">
              <a href='#faq' style={{textDecoration:'none'}} className="hover:no-underline hover:text-[#0F0F0F] ">

              <p className="font-inter cursor-pointer font-light text-base uppercase" onClick={() => navigate('/booking')}>FAQ</p>
              </a>
              <p
                className="font-inter cursor-pointer font-light text-base uppercase"
                onClick={() => navigate('/help')}
              >
                help
              </p>
            </div>
            {accessToken && profile?.firstName && (
              <MenuCard
                onChange={(value: string, name: string, path: string) =>
                  onChangeMenuMember(value, name, path)
                }
                className="w-[137px]"
                options={optionsData}
                placeholder={`${
                  profile?.firstName
                    ? profile?.firstName?.includes('@')
                      ? profile?.firstName?.split('@')[0]?.length > 5
                        ? profile?.firstName?.split('@')[0]?.slice(0, 5) + '...'
                        : profile?.firstName?.split('@')[0]
                      : profile?.firstName.length > 5
                      ? profile?.firstName.slice(0, 5) + '...'
                      : profile?.firstName
                    : profile?.email?.split('@')[0]?.length > 5
                    ? profile?.email?.split('@')[0]?.slice(0, 5) + '...'
                    : profile?.email?.split('@')[0]
                }`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
