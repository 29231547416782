import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';
import { Button, IconSvg } from 'src/components/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'src/components/common/form';
import { useHttp } from 'src/hooks';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('last name is required'),
});

export interface SignUpForm {
  firstName: string;
  lastName: string;
}

export function Signup() {
  const { accountType } = useParams();
  const { loginWithRedirect } = useContext(AuthContext);
  const { appConfig, accessToken, fetchProfile, isFetchingProfile, profile } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { sendRequest: createUser, isLoading: createUserLoaing } = useHttp();
  const { sendRequest: createUserForXploriusDb, isLoading: createUserLoaingForXplorius } =
    useHttp();

  const signupHandler = () => {
    console.log(54678);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema,
    onSubmit: async values => {
      console.log({ values });
      await createUserForXploriusDb(
        {
          url: 'user/name',
          method: 'POST',
          payload: { ...values },
          accessToken,
        },
        (data: any) => {
          fetchProfile();
          navigate('/profile');
        },
      );
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };

  useEffect(() => {
    if (profile && profile.account) {
      navigate('/profile');
    }
  }, [profile]);

  useEffect(() => {
    if (accessToken && appConfig && !appConfig.isRegistered) {
      createNewUser();
    }
  }, [accessToken, appConfig]);
  const createNewUser = async () => {
    await createUser(
      {
        url: 'user',
        method: 'POST',
        accessToken,
      },
      (data: any) => {
        fetchProfile();
      },
    );
  };

  return (
    <>
      {(createUserLoaing || createUserLoaingForXplorius || !profile) && <LoadingSpinner />}
      <div className=" w-full mx-auto h-full">
        <div className="h-full flex ">
          <div className="w-full flex items-center justify-center bg-[#EFE8DF]">
            <div className="w-[418px] ">
              <h3 className="text-xl font-bold text-[#0F0F0F]">Welcome to XPLORIUS.</h3>
              <h6 className="text-base font-light mt-1 text-[#4A4A4A]">
                Sign up in a minute and unlock unlimited hotel deals..
              </h6>
              <form onSubmit={handleSubmit}>
                <div className="mt-[30px]">
                  <div className="flex items-center gap-5">
                    <InputText
                      id={'city'}
                      name={'firstName'}
                      label={'First Name'}
                      placeholder={'Enter first name'}
                      value={values['firstName' as keyof SignUpForm]}
                      onChange={onChangeHandler}
                      //   isDisabled={item.disabled}
                      // wrapperStyles={styles['field-wrapper']}
                      isError={
                        touched['firstName' as keyof SignUpForm] &&
                        !!errors['firstName' as keyof SignUpForm]
                      }
                      errorMessage={errors['firstName' as keyof SignUpForm]}
                    />
                    <InputText
                      id={'city'}
                      name={'lastName'}
                      label={'Last Name'}
                      placeholder={'Enter last name'}
                      value={values['lastName' as keyof SignUpForm]}
                      onChange={onChangeHandler}
                      //   isDisabled={item.disabled}
                      // wrapperStyles={styles['field-wrapper']}
                      isError={
                        touched['lastName' as keyof SignUpForm] &&
                        !!errors['lastName' as keyof SignUpForm]
                      }
                      errorMessage={errors['lastName' as keyof SignUpForm]}
                    />
                  </div>
                  {/* <div className="my-4">
                  <InputText
                    id={'email'}
                    name={'email'}
                    label={'Email'}
                    placeholder={'Enter email'}
                    value={values['email' as keyof SignUpForm]}
                    onChange={onChangeHandler}
                    //   isDisabled={item.disabled}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={
                      touched['email' as keyof SignUpForm] && !!errors['email' as keyof SignUpForm]
                    }
                    errorMessage={errors['email' as keyof SignUpForm]}
                  />
                </div>
                <div className="">
                  <InputText
                    id={'password'}
                    name={'password'}
                    label={'Password'}
                    placeholder={'Enter password'}
                    type="password"
                    value={values['password' as keyof SignUpForm]}
                    onChange={onChangeHandler}
                    //   isDisabled={item.disabled}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={
                      touched['password' as keyof SignUpForm] &&
                      !!errors['password' as keyof SignUpForm]
                    }
                    errorMessage={errors['password' as keyof SignUpForm]}
                  />
                </div> */}
                  <div className="mt-4">
                    <Button
                      size="medium"
                      type="submit"
                      color="yellow"
                      label={
                        <p className="text-[#0F0F0F] font-medium text-base ">CREATE ACCOUNT</p>
                      }
                      className="w-full"
                    />
                  </div>
                  {/* <div className="flex items-center w-full mt-6">
                  <div className="w-full border-t border-[#959595]"></div>
                  <p className="text-[#959595] font-medium text-lg px-4">or</p>
                  <div className="w-full border-t border-[#959595]"></div>
                </div> */}

                  {/* <div className="border mt-4 rounded-[5px] border-[#959595] py-2 flex justify-center items-center gap-4">
                  <IconSvg icon="google" />
                  <p className="text-[#4A4A4A] text-lg font-medium">Sign up with Google</p>
                </div> */}
                </div>
              </form>
            </div>
          </div>
          <div className="w-full h-full font-inter flex flex-col justify-center items-center bg-[#C56B48]">
            <IconSvg icon="ninaKovalenko" />
            <p className="text-center text-xl font-medium text-[#EFE8DF] mt-9 mb-5 ">
              &quot;Did you know that you overpay{' '}
              <span className="font-bold">
                35% on average <br /> every time you book hotels at publicly available <br /> rates
              </span>
              ? Join us and save up to 70% on every booking <br /> with XPLORIUS&quot;
            </p>
            <p className="text-base font-medium text-[#EFE8DF] text-center">- Nina Kovalenko</p>
            <p className="text-[30px] font-whisper-Regular font-normal text-[#EFE8DF] text-center">Founder</p>
          </div>
        </div>
      </div>
    </>
  );
}
