import ModalOverlay from '../modal-overlay/ModalOverlay';

import styles from './LoadingSpinner.module.scss';

export function LoadingSpinner() {
  return (
    <ModalOverlay>
      <div className={styles['pos-center']}>
        <div className={styles['loader']}></div>
      </div>
    </ModalOverlay>
  );
}
