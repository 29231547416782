import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSvg } from 'src/components/common';
import { AuthContext } from 'src/contexts';
import { Icons } from 'src/types/icons';
import { useHttp } from '../../hooks';
import { signOut } from 'src/utils/cookieUtils';

interface SidebarItems {
  title: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}

export function Sidebar() {
  const { pathname } = useLocation();
  const { logout, accessToken, userAuth0, profile, customLogoutHandler, appConfig } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const [myTripUrl, setMyTripUrl] = useState<string>('');
  const [planSubscriptionState, setPlanSubscriptionState] = useState(false);

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const sidebarItems: SidebarItems[] = [
    {
      title: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCream',
      path: '/profile',
    },
    {
      title: 'Book now',
      active: pathname.includes('book-now'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowBlack',
      path: '/booking',
    },
    {
      title: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      title: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCream',
      path: '/billing',
    },
    {
      title: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCream',
      path: '/help',
    },
  ];

  if (appConfig?.isAdmin) {
    sidebarItems.push({
      title: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCream',
      path: '/coupons',
    });
  }
  

  const signOutHandler = () => {
    // logout();
    customLogoutHandler();
  };

  useEffect(() => {
    if (userAuth0?.sub && !profile?.account?.plan) {
      setPlanSubscriptionState(true);
    } else {
      setPlanSubscriptionState(false);
    }
  }, [userAuth0?.sub, profile?.account?.plan, location.pathname]);

  const navigateHandler = (title: string, path: string) => {
    if (planSubscriptionState) return;
    if (title === 'My trips') {
      window.open(path, '_blank');
      return;
    }
    navigate(path);
  };
  return (
    <>
      <div
        className={`border border-[#959595] w-full rounded-[5px]  ${
          appConfig?.isAdmin ? 'h-[511px]' : 'h-[456px]'
        }`}
      >
        {sidebarItems.map((item, i) => (
          <div
            className={`flex items-center  pl-8  w-full py-[22px] gap-4 ${
              item.active ? 'bg-[#C56B48] rounded-[5px]' : ''
            } ${planSubscriptionState ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            key={i}
            onClick={() => navigateHandler(item.title, item.path)}
          >
            <IconSvg
              icon={item.active ? item.activeIcon : item.icon}
              className={`w-[29px] h-[28px] ${
                planSubscriptionState ? '!cursor-not-allowed' : 'cursor-pointer'
              }`}
            />
            <p
              className={`font-inter  text-[24px] leading-[28.8px] ${
                item.active ? 'text-[#EFE8DF] font-medium font-inter' : 'text-[#4A4A4A] font-light'
              }`}
            >
              {item.title}
            </p>
          </div>
        ))}
        <div
          className={`flex items-center cursor-pointer pl-8 gap-4  w-full py-[22px] `}
          onClick={signOutHandler}
        >
          <IconSvg icon="signOutBlack" className="w-[29px] h-[28px]" />
          <p className="font-inter text-[#4A4A4A] font-light text-[24px] leading-[28.8px]">
            Sign out
          </p>
        </div>
      </div>
    </>
  );
}
