import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IProps {
  children: JSX.Element;
  isOpen: boolean;
  close: () => void;
  isStatic?: boolean;
  loading?: boolean;
  className?: string;
  disable?: boolean;
  onSubmit?: (value?:any) => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  buttonsConteinerClassName?: string;
  submitButtonClassName?: string;
  cancelButtonClassName?: string;
  withCrossBtn?: boolean;
}
export function FullPageModal({
  children,
  isOpen,
  close,
  isStatic,
  loading = false,
  className = '',
  disable = false,
  onSubmit,
  submitButtonText = '',
  cancelButtonText = '',
  buttonsConteinerClassName = '',
  submitButtonClassName = '',
  cancelButtonClassName = '',
  withCrossBtn = false,
}: IProps) {
  return isOpen ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={isStatic ? () => null: close }>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10">
          <div className="flex h-screen w-screen items-center justify-center text-center sm:items-center sm:p-0 p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform bg-white p-6 text-left shadow-xl transition-all w-screen h-screen height-[100vh] overflow-auto ${className}`}>


                {children}

      
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : (
    <>{null}</>
  );
}
