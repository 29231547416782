import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from 'src/types/icons';
import { ReactNode, useState } from 'react';
import { IconSvg } from '../common';

interface Testimonials {
  name: string;
  location: string;
  message: ReactNode;
  icon: Icons;
}
export function OtherMemberSayTablet() {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials: Testimonials[] = [
    {
      name: 'Rohallah',
      location: 'London, UK',
      message: (
        <p>
          I{`'`}ve
          <span className="font-bold font-inter ml-1 ">
            saved 522 USD on my first booking with XPLORIUS.
          </span>{' '}
          With such savings right out of the gate, I{`'`}m already convinced that the annual
          subscription fee was a wise investment.
        </p>
      ),
      icon: 'dummyAvatar',
    },
    {
      name: 'Luka & Maria',
      location: 'Dubai, UAE',
      message: (
        <p>
          The value is undeniable. What truly sets XPLORIUS apart is{' '}
          <span className="font-bold font-inter">the exceptional customer service,</span> always
          willing to go the extra mile for the members.
        </p>
      ),
      icon: 'dummyAvatar2',
    },
    {
      name: 'Muhammad',
      location: 'Toronto, Canada',
      message: (
        <p>
          I can&apos;t believe how much I was overpaying for hotels before discovering XPLORIUS.A
          big bonus for me personally is the ability to
          <span className="font-bold font-inter ml-1">
            choose between paying in fiat or crypto.
          </span>
        </p>
      ),
      icon: 'dummyAvatar3',
    },
  ];

  const settingsMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    // centerHeight: '227px',
    centerWidth: 'w-full',
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => setCurrentIndex(newIndex),

    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div className="arrow" onClick={() => sliderRef && sliderRef.slickPrev()}>
        <IconSvg icon="leftLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
    nextArrow: (
      <div className="arrow " onClick={() => sliderRef && sliderRef.slickNext()}>
        <IconSvg icon="rightLongArrowGreen" className="w-[38px] h-[16px]" />
      </div>
    ),
  };
  return (
    <>
      <div className="slick-slider-other-member-say-tablet mt-5 ">
        <Slider
          {...settingsMobileScreen}
          ref={slider => setSliderRef(slider)}
          className=""
        >
          {testimonials.map((testimonial, index) => (
            <div
              className="border  border-[#4A4A4A] rounded-xl pl-[30px] py-[30px] pr-[30px]"
              key={index}
            >
              <p className="leading-[19.2px] text-base font-light font-inter text-[#4A4A4A] h-[114px]">
                {testimonial.message}
              </p>
              <div className="flex gap-[29px] mt-5">
                <div className="h-[95px] w-[95px] rounded-full">
                  <IconSvg icon={testimonial.icon} />
                </div>
                <div>
                  <p className="font-medium font-inter text-[#0F0F0F]">{testimonial.name}</p>
                  <p className="mt-0">{testimonial.location}</p>
                  <IconSvg className="mt-2" icon="stars" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
          className={` flex items-center justify-between mt-5 w-full`}
        >
          {settingsMobileScreen.prevArrow}
          {settingsMobileScreen.nextArrow}
        </div>
    </>
  );
}
