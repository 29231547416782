import { useEffect, useState } from 'react';
import { Footer, IconSvg } from 'src/components/common';
import { useLocation, useNavigate } from 'react-router-dom';
import LineAndDotSteps from 'src/components/about-us/LineAndDotSteps';
import { Icons } from 'src/types/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../../components/about-us/AboutUs.module.scss';

interface NftCollection {
  img: Icons;
}
export function AboutUs() {
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [sliderRefMobile, setSliderRefMobile] = useState<Slider | null>(null);
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>('');
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const stepsKeyPoints = [
    {
      item: 'How it all started',
      id: 'how-it-started',
    },
    {
      item: "Founder's background",
      id: 'founders-background',
    },
    {
      item: "Where we're at",
      id: 'where-were-at',
    },
    {
      item: "Where we're heading towards",
      id: 'where-were-heading-towards',
    },
    {
      item: 'Welcome to our community',
      id: 'welcome-to-our-community',
    },
  ];

  const nftSliderSettings = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div
        className="arrow h-[52px] w-[52px] rounded-full border border-[#A4A4A4] flex justify-center items-center cursor-pointer"
        onClick={() => {
          console.log('test', sliderRef);
          sliderRef && sliderRef.slickPrev();
        }}
      >
        <IconSvg icon="arrowLeftSlider" />
      </div>
    ),
    nextArrow: (
      <div
        className="arrow h-[52px] w-[52px] rounded-full border border-[#A4A4A4] flex justify-center items-center cursor-pointer"
        onClick={() => sliderRef && sliderRef.slickNext()}
      >
        <IconSvg icon="arrowRightSlider" />
      </div>
    ),
  };
  const nftSliderSettingsSmallAndMobileScreen = {
    centerMode: true,
    centerPadding: '0',
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: (
      <div
        className="arrow h-[30px] w-[30px] rounded-full border border-[#A4A4A4] flex justify-center items-center cursor-pointer"
        onClick={() => sliderRefMobile && sliderRefMobile.slickPrev()}
      >
        <IconSvg icon="arrowLeftSlider" className="" />
      </div>
    ),
    nextArrow: (
      <div
        className="arrow h-[30px] w-[30px] rounded-full border border-[#A4A4A4] flex justify-center items-center cursor-pointer"
        onClick={() => sliderRefMobile && sliderRefMobile.slickNext()}
      >
        <IconSvg icon="arrowRightSlider" className="" />
      </div>
    ),
  };

  const nfts: NftCollection[] = [
    {
      img: 'nft15',
    },
    {
      img: 'nft63',
    },
    {
      img: 'nft137',
    },
    {
      img: 'nft216',
    },
    {
      img: 'nft236',
    },
    {
      img: 'nft253',
    },
    {
      img: 'nft362',
    },
    {
      img: 'nft663',
    },
    {
      img: 'nft682',
    },
    {
      img: 'nft765',
    },
    {
      img: 'nft810',
    },
    {
      img: 'nft900',
    },
    {
      img: 'nft1152',
    },
    {
      img: 'nft1196',
    },
  ];
  console.log({ activeTab });

  return (
    <>
      <div className=" w-full ">
        <div
          className="lg:h-[70vh] h-[600px] bg-center bg-cover bg-no-repeat w-full p-10"
          style={{ backgroundImage: `url(/assets/images/aboutUsTopBackground.jpg)` }}
        >
          <div className='w-full h-full flex flex-col justify-center items-center '>
            <div className=" flex !items-start lg:!justify-start justify-center rounded w-full">
              <IconSvg
                className="lg:w-[231px] lg:h-[54px] w-[92px] h-[22px] "
                icon="xploriusLogoCream"
                onClick={() => navigate('/')}
              />
            </div>
            <div className="flex justify-center items-center h-full">
              <p className=" font-bigCaslon font-medium lg:text-[70px] text-[40px] text-[#EFE8DF] lg:leading-[84.63px] leading-[47.99px]">
                OUR <span className='italic'>story</span>
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#EFE8DF] lg:pt-[44px] sm:pt-0 pb-[61px] flex justify-center ">
          <div className="w-full h-full flex items-start justify-center lg:px-10 px-2.5 max-w-[1320px]">
            <div
              className="lg:block hidden w-[330px] pt-[106px] sticky top-0 "
              style={{ scrollbarWidth: 'none' }}
            >
              {stepsKeyPoints.map((keyPoint, i) => (
                <a
                  href={`#${keyPoint.id}`}
                  key={i}
                  className="hover:no-underline hover:text-[#0F0F0F] w-full"
                  style={{ textDecoration: 'none' }}
                  onClick={() => setActiveTab(keyPoint.id)}
                >
                  <div className="flex items-center gap-[7px] pt-[22px] w-full">
                    <div className="w-[30px]">
                      {activeTab == keyPoint.id && <IconSvg icon="longArrowLeftToRightBlack" />}
                    </div>
                    <p
                      className={`font-light text-base leading-[19.2px] ${
                        keyPoint.id == activeTab ? 'text-[#0F0F0F]' : 'text-[#A4A4A4]'
                      }`}
                    >
                      {keyPoint.item}
                    </p>
                  </div>
                </a>
              ))}
            </div>
            <div className={`w-auto mx-auto ${styles['about-us-container']}`}>
              <LineAndDotSteps
                setActiveTab={setActiveTab}
                stepsItems={[
                  {
                    id: 'how-it-started',
                    status: 'complete',
                    title: (
                      <div className="lg:flex items-start w-full ">
                        <div className="lg:w-[350px] sm:w-full">
                          <div className="">
                            <h2 className="font-bigCaslon-Italic italic font-normal lg:text-[32px] text-lg color-[#0F0F0F] sm:leading-[38.39px] leading-[21.59px] sm:pt-0 pt-[5px]">
                              How it all started
                            </h2>
                            <p className="font-inter font-light lg:text-lg text-sm leading-[21.6px] color-[#0F0F0F] lg:pt-10 sm:pt-[15px] pt-5">
                              Hey there! This is Nina, founder of XPLORIUS. Here’s a little
                              sneak-peek into my background and our journey (so <br className='lg:block sm:hidden block' /> far!). <br />
                              <br />
                              XPLORIUS was established in Dubai in 2021. The company was initially
                              launched as a small NFT project, uniting people passionate about
                              adventure in the web3 space.The idea reflected both my personal and
                              professional life, which has always revolved around travel,
                              multicultural experiences and innovation.
                            </p>
                          </div>
                        </div>
                        <div className="lg:block hidden" style={{ width: 'calc(100% - 350px)' }}>
                          <div className="slick-slider-container ">
                            <Slider {...nftSliderSettings} ref={slider => setSliderRef(slider)}>
                              {nfts.map((nft, i) => (
                                <div key={i}>
                                  <div className="slider-item ml-10">
                                    <IconSvg
                                      icon={nft.img}
                                      className="w-[221pxpx]  rounded-[10px]"
                                    />
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </div>
                          <div className="slick-arrows flex items-center justify-between mt-2.5 pl-10">
                            <div className="flex items-center gap-2.5 ">
                              {nftSliderSettings.prevArrow}
                              {nftSliderSettings.nextArrow}
                            </div>
                            <p className="font-whisper-Regular text-[#0F0F0F] font-normal text-[33px] leading-[51.2px]">
                              xplorius nft collection
                            </p>
                          </div>
                        </div>

                        {/* This slider component for small and mobile screen Start */}

                        <div className="lg:hidden block w-full sm:mt-[26px] mt-[35px]">
                          <div className="grid grid-cols-12">
                            <div className="sm:col-span-6"></div>
                            <div className="sm:col-span-6 col-span-12">
                              {' '}
                              <div className="slick-slider-container ">
                                <Slider
                                  {...nftSliderSettingsSmallAndMobileScreen}
                                  ref={slider => setSliderRefMobile(slider)}
                                >
                                  {nfts.map((nft, i) => (
                                    <div key={i}>
                                      <div className="slider-item ml-2.5">
                                        <IconSvg
                                          icon={nft.img}
                                          className="sm:w-[146px] sm:h-[152px] w-full  rounded-[5px]"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </Slider>
                              </div>
                              <div className="slick-arrows flex items-center justify-between mt-1.5">
                                <div className="flex items-center justify-between w-full ">
                                  {nftSliderSettingsSmallAndMobileScreen.prevArrow}
                                  <p className="font-whisper-Regular text-[#0F0F0F] font-normal text-[25px] leading-[51.2px]">
                                    xplorius nft collection
                                  </p>
                                  {nftSliderSettingsSmallAndMobileScreen.nextArrow}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* This slider component for small and mobile screen End */}
                      </div>
                    ),
                  },
                  {
                    id: 'founders-background',
                    status: 'complete',
                    title: (
                      <div className="lg:flex items-start  w-full ">
                        <div className={`${styles['founder-sections']}`}>
                          <div className="lg:pr-[38px]">
                            <h2 className="font-bigCaslon-Italic italic font-normal lg:text-[32px] text-lg color-[#0F0F0F] sm:leading-[38.39px] leading-[21.59px] sm:pt-0 pt-[5px]">
                            Founder&apos;s background
                            </h2>
                            <p className="font-inter font-light lg:text-lg text-sm leading-[21.6px] color-[#0F0F0F] lg:pt-10 sm:pt-[15px] pt-[25px]">
                              My professional background is deeply rooted in PR & communications for
                              the luxury hospitality industry, having worked with brands such as
                              Four Seasons Hotels & Resorts, JOALI Maldives, Palazzo Versace Dubai,
                              Regnum Carya, Chenot Group, and Les Grandes Alpes (Courchevel 1850).
                            </p>
                            <p className="font-inter font-light lg:text-lg sm:text-sm leading-[21.6px] color-[#0F0F0F] lg:pt-5 sm:pt-[15px]">
                              At some point in my career, I shifted from working with hospitality
                              brands to developing communication strategies for startups. This is
                              when I developed my passion for different technologies, including (but
                              not limited to!) NFTs, web3, and blockchain.
                            </p>
                          </div>
                        </div>
                        <div className="lg:w-[350px] sm:w-full sm:flex flex-col justify-end items-end lg:mt-0 sm:mt-[30px] mt-2.5 ">
                          <div>
                            <IconSvg
                              icon="founderPng"
                              className="lg:w-[350px] rounded-[5px]  sm:w-[300px]  w-full sm:block hidden"
                            />
                            <IconSvg
                              icon="founderPng"
                              className="w-full rounded-[5px] sm:hidden block"
                            />
                            <p className="hidden  font-whisper-Regular text-[#0F0F0F] font-normal sm:text-[40px] text-xl sm:leading-[51.2px] leading-[25.6px] sm:flex justify-center lg:w-auto sm:w-full">
                              founder
                            </p>
                            <p className="sm:hidden  font-whisper-Regular text-[#0F0F0F] font-normal sm:text-[40px] text-xl sm:leading-[51.2px] leading-[25.6px] flex justify-center lg:w-auto sm:w-full mt-5">
                            nina, founder
                            </p>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'where-were-at',
                    status: 'complete',
                    title: (
                      <div className="">
                        <div>
                          <h2 className="font-bigCaslon-Italic italic font-normal lg:text-[32px] text-[18px] color-[#0F0F0F] sm:leading-[38.39px] leading-[21.59px] sm:pt-0 pt-[5px]">
                            Where we&apos;re at
                          </h2>
                          <div className="lg:mt-10 sm:mt-[15px] mt-[25px]">
                            <p className="font-inter font-light lg:text-lg text-sm leading-[21.6px] color-[#0F0F0F]">
                              At this point in our journey, we are focused on scaling our{' '}
                              <span className="font-medium">
                                bespoke online travel agency sourcing unbeatable deals, while
                                delivering premium service.
                              </span>{' '}
                              We believe that there&apos;s nothing more exciting for a frequent
                              traveler than securing a sweet deal on a favorite hotel and we are
                              committed to bringing the best travel opportunities to our members.
                            </p>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'where-were-heading-towards',
                    status: 'complete',
                    title: (
                      <div>
                        <div>
                          <h2 className="font-bigCaslon-Italic italic font-normal lg:text-[32px] text-[18px] color-[#0F0F0F] sm:leading-[38.39px] leading-[21.59px] sm:pt-0 pt-[5px]">
                            Where we&apos;re heading towards
                          </h2>
                          <div className="lg:mt-10 sm:mt-[15px] mt-5">
                            <p className="font-inter font-light lg:text-lg text-sm leading-[21.6px] color-[#0F0F0F]">
                              Our commitment is to continuously expand our network and utilize
                              innovative technology to secure the most attractive offers for hotels,
                              flights, and travel experiences. We aim to set a new benchmark in the
                              travel industry, where exceptional value and customer satisfaction are
                              at the heart of every adventure we facilitate.
                            </p>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'welcome-to-our-community',
                    status: 'complete',
                    title: (
                      <div className="flex">
                        <div>
                          <h2 className="font-bigCaslon-Italic italic font-normal lg:text-[32px] text-[18px] color-[#0F0F0F] sm:leading-[38.39px] leading-[21.59px] sm:pt-0 pt-[5px]">
                            Welcome to our community
                          </h2>
                          <div className="lg:mt-10 sm:mt-[15px] mt-5">
                            <p className="font-inter font-light lg:text-lg sm:text-sm leading-[21.6px] color-[#0F0F0F]">
                              I am delighted to welcome you to our growing international community
                              of digital nomads, luxury travelers, wellness enthusiasts, and
                              generally just people with curiosity and adventure at heart. <br />
                              <br />
                              Let&nbsp;s explore together!
                            </p>
                            <p className="font-whisper-Regular font-normal text-[32px] leading-[40.96px] text-[#0F0F0F] mt-5">
                              Yours truly,
                              <br />
                              Nina.
                            </p>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div
          className="lg:h-[832px] h-[600px] w-full bg-center bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(/assets/images/aboutUsChineseBackground.jpg)` }}
        >
          <div className=" flex items-center justify-center rounded w-full h-full">
            <IconSvg
              className="lg:w-[558px] lg:h-[133px] sm:w-[177px] sm:h-[40.64px] w-[146.46px] h-[33.52px]"
              icon="xploriusLogoCream"
            />
          </div>
        </div>
        <div className="w-full bg-[#EFE8DF] ">
          <div className=" sm:py-[46px] py-6 sm:px-10 px-4">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
