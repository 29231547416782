import { useMemo } from 'react';

import styles from './CompanyImgPlaceholder.module.scss';

import defaultLogo from './icon/company.svg';
import logoXs from './icon/company-logo-xs.svg';
import logoSm from './icon/company-logo-sm.svg';
import logoMd from './icon/company-logo-md.svg';
import logoLg from './icon/company-logo-lg.svg';
import logoXlg from './icon/company-logo-xlg.svg';

interface Props {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'x-lg';
  isCursorPointer?: boolean;
  onClick?: () => void;
  className?: string;
}

export function CompanyImgPlaceholder({ size, isCursorPointer = false, onClick, className='' }: Props) {
  const styleSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return styles['icon-wrapper-xs'];

      case 'sm':
        return styles['icon-wrapper-sm'];

      case 'md':
        return styles['icon-wrapper-md'];

      case 'lg':
        return styles['icon-wrapper-lg'];

      default:
        return styles['icon-wrapper-x-lg'];
    }
  }, [size]);

  const iconSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return logoXs;

      case 'sm':
        return logoSm;

      case 'md':
        return logoMd;

      case 'lg':
        return logoLg;

      default:
        return logoXlg;
    }
  }, [size]);

  return (
    <div
      role={isCursorPointer && onClick ? 'button' : ''}
      onClick={onClick}
      style={isCursorPointer ? { cursor: 'pointer' } : {}}
      className={`${className} ${styles['icon-wrapper']} ${styleSize}`}
    >
      <img src={defaultLogo} alt="company default icon" />
    </div>
  );
}
