import { useLocation, useNavigate } from 'react-router-dom';
import { Button, IconSvg } from 'src/components/common';
import { useHttp, useNotification } from '../../../hooks';
import React, { useState, useContext, useEffect, RefObject, useRef } from 'react';
import { AuthContext } from '../../../contexts';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';

export function Billing() {
  const notify = useNotification();
  const navigate = useNavigate();
  const { sendRequest, isLoading: isLoadingIntent } = useHttp();
  const [clientSecret, setClientSecret] = useState<string>('');
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [isBillingLoaded, setBillingLoaded] = useState<boolean>(false);
  const { accessToken, profile, userAuth0 } = useContext(AuthContext);

  const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  if (!stripeKey) {
    throw new Error('Stripe public key is not defined');
  }
  // const stripePromise = loadStripe(`${stripeKey}`);
  const stripePromise = loadStripe(stripeKey);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const setup_intent = params.get('setup_intent');
  const setup_intent_client_secret = params.get('setup_intent_client_secret');
  const redirect_status = params.get('redirect_status');

  const setDefaultPaymentMethod = () => {
    sendRequest(
      {
        url: `payment/set-default-payment-method/${setup_intent}`,
        method: 'POST',
        payload: { setDefault: 'true' },
        accessToken,
      },
      (response: any) => {
        notify(`${response.message}`, 'success');
        const urlWithoutParams = window.location.origin + window.location.pathname;
        window.history.replaceState(null, '', urlWithoutParams);
      },
    );
  };
  useEffect(() => {
    if (redirect_status === 'succeeded' && accessToken) {
      setDefaultPaymentMethod();
    }
  }, [setup_intent, setup_intent_client_secret, redirect_status, accessToken]);

  const getIntent = () => {
    sendRequest(
      {
        method: 'POST',
        url: `payment/payment-method-intent`,
        payload: {
          firstName: profile?.firstName,
          lastName: profile?.lastName,
          priceId: profile?.account?.plan?.priceId,
        },
        accessToken,
      },
      (intent: any) => {
        setClientSecret(intent.secret);
      },
    );
  };

  const appearance = {
    variables: {
      colorPrimary: '#000000',
      colorBackground: '#EFE8DF',
      colorText: '#000000',
      colorDanger: '#df1b41',
      spacingUnit: '2px',
      borderRadius: '5px',
      
    },
    rules: {
      '.Input': {
        borderColor: '#959595',
        color: '#0F0F0F',
        padding: "8px 12px",
        fontFamily: "'Inter'",
      },
      '.Input:focus': {
        borderColor: '#959595',
      },
      '.Label': {
        color: '#0F0F0F',
        fontFamily: "'Inter'",
        fontSize: '14px',
        marginBottom: '8px',
        marginTop: '12px',
        lineHeight: "24px",
        fontWeight: "700 !important",
      },
      
    },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance
  };

  const addPaymentMethodHandler = () => {
    const baseUrl =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
    console.log('baseUrl', `${baseUrl}/profile/billing`);
    if (accessToken && profile) {
      console.log(profile, 'this is profile');
      setAddPaymentMethod(true);
      getIntent();
    }
  };

  const formRef: RefObject<{ handleSubmit: () => void }> = useRef(null);
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<any>([]);
  const getCompanyPaymentMethods = () => {
    setIsPaymentMethodLoading(true);
    sendRequest(
      {
        url: 'payment/payment-method',
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        console.log('methods', data[0]);
        if (data.length > 0) {
          setCurrentPaymentMethod(data[0]);
        }
        setIsPaymentMethodLoading(false);
        setBillingLoaded(true);
      },
    );
  };

  useEffect(() => {
    accessToken && getCompanyPaymentMethods();
  }, [accessToken]);

  return (
    <>
      <div className="lg:pb-48">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Billing information
        </h2>

        {!isBillingLoaded || !profile ? (
          <div className="border border-[#959595] rounded-[5px] p-7 mt-4 min-h-52"></div>
        ) : (
          <>
            {addPaymentMethod ? (
              <div className="border border-[#959595] rounded-[5px] p-7 mt-4">
                <div className="">
                  {clientSecret ? (
                    <div>
                      <Elements stripe={stripePromise} options={options}>
                        <SetupForm ref={formRef} setAddPaymentMethod={setAddPaymentMethod} />
                      </Elements>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                {profile?.account.defaultStripePaymentMethod ? (
                  <div className="border border-[#959595] rounded-[5px] lg:p-7 sm:p-6 p-4 lg:mt-4 mt-3">
                    <div className="flex sm:flex-row flex-col sm:justify-between sm:items-start sm:space-y-0 space-y-5">
                      <div>
                        <div>
                          <p className="text-[#0F0F0F] font-bold font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px]">
                            Current billing:
                          </p>
                          <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] mt-[10px] uppercase">
                            {currentPaymentMethod?.brand}
                          </p>
                        </div>
                        <div className="mt-[30px]">
                          <p className="text-[#0F0F0F] font-bold font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px]">
                            {currentPaymentMethod?.cardHolderName}
                          </p>
                          <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] mt-[10px]">
                            **** **** **** {currentPaymentMethod?.last4}
                          </p>
                          <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] mt-[10px]">
                            {currentPaymentMethod?.exp_month}/{currentPaymentMethod?.exp_year}
                          </p>
                        </div>
                        {/*<div className="mt-[30px]">*/}
                        {/*  <p className="text-[#0F0F0F] font-bold font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px]">*/}
                        {/*    Billing address:*/}
                        {/*  </p>*/}
                        {/*  <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] mt-[10px]">*/}
                        {/*    {currentPaymentMethod?.address}*/}
                        {/*  </p>*/}
                        {/*</div>*/}
                      </div>
                      <Button
                        size="small"
                        color="primary"
                        label={
                          <p className="uppercase text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                            UPDATE BILLING DETAILS
                          </p>
                        }
                        className="lg:px-0 sm:px-0 px-0 lg:!w-[245px] sm:!w-[210px] !w-[173px]"
                        onClick={() => addPaymentMethodHandler()}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-between items-start mt-6">
                    <Button
                      size="small"
                      color="primary"
                      label={
                        <p className="uppercase text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                          ADD BILLING DETAILS
                        </p>
                      }
                      className="lg:px-6 sm:px-0 px-6 lg:w-auto sm:!w-[210px]"
                      onClick={() => addPaymentMethodHandler()}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {isLoadingIntent && <LoadingSpinner />}
    </>
  );
}
