import { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/hooks';

type Props = {
  children: JSX.Element;
};

type Status = 'InProgress' | 'Rejected' | 'Failed' | 'Approved';

type Notification = {
  content: {
    event: string;
    eventStatus: Status;
  };
  id: string;
  read: boolean;
};

const NotificationsWrapper = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const { accessToken } = useContext(AuthContext);
  const { sendRequest } = useHttp();
  const notify = useNotification();
  const { profile } = useContext(AuthContext);

  const fetchNotifications = () => {
    // sendRequest(
    //   {
    //     url: 'notification',
    //     accessToken,
    //   },
    //   res => {
    //     setNotifications((res as any).data);
    //   },
    // );
  };

  const readNotification = (id: string) => {
    sendRequest(
      {
        method: 'PUT',
        url: `notification/${id}`,
        accessToken,
      },
      () => {
        return;
      },
    );
  };
  const prepareCurrentNotification = (status: Status, id: string) => {
    return ""
  };

  useEffect(() => {
    if (profile?.account?.company?.identity) {
      fetchNotifications();
    }
  }, [profile]);

  useEffect(() => {
    notifications
      .filter(({ content: { event }, read }) => event === 'Identity Verification' && !read)
      .forEach(({ content: { eventStatus }, id }) => {
        if (eventStatus === 'Rejected' && profile?.account?.company?.identity?.identityVerificationAttempts === 0) {
          prepareCurrentNotification('Failed', id);
        } else {
          prepareCurrentNotification(eventStatus, id);
        }
      });
  }, [notifications]);

  return (
    <>
      {children}
      <ToastContainer autoClose={8000} hideProgressBar={true}/>
    </>
  );
};

export default NotificationsWrapper;
