import { Outlet } from 'react-router-dom';
import { Layout } from 'src/layout';

export default function XploriusLayoutPage() {
  return (
    <>
      <div className="h-full w-full">
        <Layout>
          <Outlet />
        </Layout>
      </div>
    </>
  );
}
