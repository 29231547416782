import React, { Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';
import { AuthContext } from 'src/contexts';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface DropdownMenuProps {
  name?: string;
  options: DropdownMenuOption[];
  onChange: (value: string, name: string, activeIcon: Icons, path: string, active: boolean) => void;
  defaultValue?: string;
  background?: string;
  placeholder?: string;
  icon?: boolean;
  className?: string;
  activeIcon: Icons;
}

export interface DropdownMenuOption {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}

export function MenuCardMobile({
  name,
  className = '',
  options = [],
  defaultValue = '',
  background = '',
  onChange,
  icon = false,
  placeholder = 'Select item',
  activeIcon,
}: DropdownMenuProps) {
  const location = useLocation();
  const [value, setValue] = useState(defaultValue);
  const [actionName, setActionName] = useState(defaultValue);
  const [planSubscriptionState, setPlanSubscriptionState] = useState(false);
  const { logout, accessToken, userAuth0, profile } = useContext(AuthContext);

  const handleItemClick = (
    value: string,
    name: string,
    activeIcon: Icons,
    path: string,
    active: boolean,
  ) => {
    if (name !== 'Sign out' && planSubscriptionState) return;
    onChange(value, name, activeIcon, path, active);
    setValue(value);
    setActionName(name);
  };

  const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the event from propagating to the parent div
  };
  const signOutHandler = () => {
    logout();
  };

  useEffect(() => {
    if (userAuth0?.sub && !profile?.account?.plan) {
      setPlanSubscriptionState(true);
    } else {
      setPlanSubscriptionState(false);
    }
  }, [userAuth0?.sub, profile?.account?.plan, location.pathname]);
  
  
  return (
    <div onClick={handleDropdownClick} className="lg:mt-0 mt-10">
      <Menu
        as="div"
        className={location.pathname.includes('watchlist') ? ' w-full' : 'relative w-full  '}
      >
        <Menu.Button
          className={`${
            icon
              ? ''
              : `w-full 
        ${
          background ? `bg-[${background}]` : ''
        }  text-base font-normal text-[#000000A3] hover:bg-gray-50 focus:outline-none`
          }`}
        >
          {icon ? (
            <IconSvg icon="bitCoin" />
          ) : (
            <div className="flex items-center justify-between py-2.5 bg-[#C56B48] sm:px-[28px] px-4 rounded-[5px]">
              <div className="sm:flex hidden"></div>
              <div className="flex items-center gap-2">
                <div className=" flex justify-center items-center rounded-full">
                  {' '}
                  <IconSvg icon={activeIcon} className='h-[19px] w-[19px]' />
                </div>
                <p className="font-inter text-lg leading-[19.2px] font-light text-[#EFE8DF]">
                  {placeholder}
                </p>
              </div>
              <IconSvg icon="downArrowHeadCream" className="mb-1" />
            </div>
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-50 absolute ${'right-0'} origin-top-right rounded-md pb-3 bg-[#EFE8DF] shadow-lg ring-1 border-x border-b border-[#959595] ring-black ring-opacity-5 focus:outline-none ${className}`}
          >
            {options.map((item: DropdownMenuOption, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div>
                    <div
                      onClick={() =>
                        handleItemClick(
                          item.value,
                          item.name,
                          item.activeIcon,
                          item.path,
                          item.active,
                        )
                      }
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900 ' : 'text-gray-900 ',
                        '  p-3 text-sm ',
                        item.name !== 'Sign out' && planSubscriptionState
                          ? '!cursor-not-allowed'
                          : 'cursor-pointer',
                      )}
                    >
                      <div className="grid grid-cols-12">
                        <div className="sm:block hidden col-span-4 "></div>
                        <div className="sm:col-span-4 col-span-12 flex  items-center justify-start gap-4 sm:pl-10 pl-0">
                          <IconSvg
                            icon={item.icon}
                            className={`h-[26px] w-[26px] ${
                              item.name !== 'Sign out' && planSubscriptionState
                                ? '!cursor-not-allowed'
                                : 'cursor-pointer'
                            }`}
                          />
                          <p className="text-base font-normal font-inter">{item.name}</p>
                        </div>
                        <div className="sm:block hidden col-span-4"></div>
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
