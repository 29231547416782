import { useLocation, useNavigate } from 'react-router-dom';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { MenuCardMobile } from './MenuCardMobile';
import { Button, IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';
import { useHttp } from 'src/hooks';
import { MenuCard } from './MenuCard';
import { signOut } from 'src/utils/cookieUtils';

interface Options {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}

interface SelectedMenuItem {
  name: string;
  value: string;
  path: string;
  active: boolean;
  activeIcon: Icons;
}

export function HeaderMobile() {
  const { fetchProfile, logout, loginWithRedirect, appConfig, userAuth0, accessToken, profile, customLogoutHandler } =
    useContext(AuthContext);
  const [myTripUrl, setMyTripUrl] = useState<string>('');
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const [selectedMenuItem, setSelectedMenuItems] = useState<SelectedMenuItem>();
  const location = useLocation();

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const signupHandler = () => {
    void loginWithRedirect({
      appState: { returnTo: `/sign-up` },
      screen_hint: 'signup',
    });
  };

  const onChangeMenuMember = (
    value: string,
    name: string,
    activeIcon: Icons,
    path: string,
    active: boolean,
  ) => {    
    if (name == 'Sign out') {
      customLogoutHandler()
      return;
    }
    setSelectedMenuItems({ value, name, activeIcon, path, active });
    navigate(path);
  };

  const onChangeMenu = (value: string, name: string, path: string) => {
    if (value == 'My trips') {
      window.open(path, '_blank');
      return;
    }
    if (value == 'Sign out') {
      customLogoutHandler()
      return;
    }
    navigate(path);
  };

  const optionsData: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCopperRed',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('book-now'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowCopperRed',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCopperRed',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  useEffect(() => {
    const selectedOption = optionsDataForMobile.find(option => option.active);    
    if (selectedOption) setSelectedMenuItems(selectedOption);
  }, [location.pathname]);

  const optionsDataForMobile: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCream',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('book-now'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowBlack',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCream',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCream',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  if (appConfig?.isAdmin) {
    const newOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/coupons',
    };
    optionsData.splice(optionsData.length - 1, 0, newOption);
    const optionsDataForMobileOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCream',
      path: '/coupons',
    };
    optionsDataForMobile.splice(optionsDataForMobile.length - 1, 0, optionsDataForMobileOption);
  }

  return (
    <>
      <div className="lg:border-b lg:border-black lg:hidden block">
        <div className="flex items-center justify-between">
          <div className=" flex items-center justify-between rounded w-full">
            <IconSvg
              icon="logoBlack"
              className="lg:w-auto lg:h-auto w-[92px] cursor-pointer"
              onClick={() => navigate('/')}
            />
            {/* <div className="h-[25px] w-[25px] bg-[#C56B48] rounded-full">
              <IconSvg icon="userCream" className="h-full w-full" />
            </div> */}
            {accessToken && profile?.firstName && (
              <MenuCard
                onChange={(value: string, name: string, path: string) =>
                  onChangeMenu(value, name, path)
                }
                className="w-[137px]"
                options={optionsData}
                // placeholder={`${profile?.firstName}`}
                placeHolderIcon={
                  <div className="h-[25px] w-[25px] bg-[#C56B48] rounded-full">
                    <IconSvg icon="userCream" className="h-full w-full" />
                  </div>
                }
                icon={true}
              />
            )}
          </div>
          <div className="flex items-center gap-6">
            <div className="lg:flex items-baseline gap-8 hidden">
              <p className="font-inter font-light text-base uppercase">FAQ</p>
              <p className="font-inter font-light text-base uppercase">help</p>
            </div>
          </div>
        </div>
        {/* {userAuth0?.sub && (
          <MenuCard
            onChange={(value: string, name: string) => onChangeMenuMember(value, name)}
            className="w-full"
            options={options}
            placeholder={`${profile?.firstName}`}
          />
        )} */}
        <MenuCardMobile
          onChange={(
            value: string,
            name: string,
            activeIcon: Icons,
            path: string,
            active: boolean,
          ) => onChangeMenuMember(value, name, activeIcon, path, active)}
          className="w-full"
          options={optionsDataForMobile}
          placeholder={`${selectedMenuItem?.name}`}
          activeIcon={selectedMenuItem?.activeIcon as keyof typeof selectedMenuItem}
        />
      </div>
    </>
  );
}
