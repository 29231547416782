import { ReactNode, useEffect, useRef, useState } from 'react'

function classNames(...classes: (false | null | undefined | string)[]) {
  return classes.filter(Boolean).join(' ')
}

interface StepsProps {
  setActiveTab: (tab:string) => void
  stepsItems:{
    title:ReactNode;
    // description:ReactNode;
    status:string,
    href?: string,
    id?: string,
  }[]
}

export default function LineAndDotSteps({stepsItems, setActiveTab}:StepsProps) {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            console.log(entry);
            
            if (entry.isIntersecting) {
              setActiveTab(entry.target.id);
            }
          });
        },
        { threshold: 0.5 } // Adjust threshold as needed
      );
  
      // Observe each section
      sectionRefs.current.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {stepsItems.map((step, stepIdx) => (
          <li key={stepIdx} className={classNames(stepIdx !== stepsItems.length - 1 ? '' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                {stepIdx !== stepsItems.length - 1 ? (
                  <div className="absolute left-[7px]  -ml-px pt-0.5 h-full w-0.5 bg-[#A4A4A4] " aria-hidden="true" />
                ) : <div className="absolute left-[7px]  -ml-px pt-0.5 lg:h-[94px] h-[60px] w-0.5 bg-[#A4A4A4] " aria-hidden="true" />}
                <section id={step.id} ref={(el) => (sectionRefs.current[stepIdx] = el as HTMLDivElement)} className={`group relative flex items-start text-[#0F0F0F] no-underline hover:text-[#0F0F0F] hover:no-underline ${stepIdx == 0 ? 'lg:pt-[106px] pt-[35px] ' : ' lg:pt-20 sm:pt-[40px] pt-[30px]'}`}>
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-[14px] w-[14px] items-center justify-center rounded-full bg-[#4C4C4C] group-hover:bg-[#4C4C4C]">
                      {/* <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
                    </span>
                  </span>
                  <span className="lg:ml-[34px] m-1.5 flex min-w-0 flex-col">
                    <span className="text-sm font-medium">{step.title}</span>
                    {/* <span className="text-sm text-gray-500">{step.description}</span> */}
                  </span>
                </section>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== stepsItems.length - 1 ? (
                  <div className="absolute left-[11px]  -ml-px mt-0.5 h-full w-0.5 bg-[#A4A4A4]" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="group relative flex items-start text-[#0F0F0F] no-underline hover:text-[#0F0F0F] hover:no-underline" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-[13px] w-[13px] items-center justify-center rounded-full border-2 border-[#4C4C4C] bg-[#4C4C4C]">
                      {/* <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" /> */}
                    </span>
                  </span>
                  <span className="ml-[34px] flex min-w-0 flex-col">
                    <span className="text-sm font-medium ">{step.title}</span>
                    {/* <span className="text-sm text-gray-500">{step.description}</span> */}
                  </span>
                </a>
              </>
            ) : (
              <>
                {stepIdx !== stepsItems.length - 1 ? (
                  <div className="absolute left-[11px] top-4 -ml-px mt-0.5 h-full w-0.5 bg-[#A4A4A4]" aria-hidden="true" />
                ) : null}
                <a href={step.href} className="group relative flex items-start text-[#0F0F0F] no-underline hover:text-[#0F0F0F] hover:no-underline">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      {/* <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" /> */}
                    </span>
                  </span>
                  <span className="ml-[34px] flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-gray-500">{step.title}</span>
                    {/* <span className="text-sm text-gray-500">{step.description}</span> */}
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}