import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styles from '../InputForm.module.scss';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AuthContext } from '../../../contexts';
import { VALIDATION_ERRORS } from '../../../constants/validationErrorMsg';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { InputText } from '../../../components/common/form';
import { Button } from '../../../components/common';

// interface BillingInformation {
//   address: string;
//   postalCode: string;
//   city: string;
//   state: string;
// }

interface SetupFormHandle {
  handleSubmit: () => void;
}

interface InputField {
  name: string;
  label: string;
  label1?: string;
  label2?: string;
  placeholder: string;
  placeholder1?: string;
  placeholder2?: string;
  disabled: boolean;
  disabled1?: boolean;
  disabled2?: boolean;
  doubleFieldsLine?: boolean;
  name1?: string;
  name2?: string;
  textArea?: boolean;
  options?: any[]; // You might need to adjust the type of options based on your actual data structure
  multiSelect?: boolean;
  doubleSelectFieldsLine?: boolean;
}

// const validationSchema = Yup.object().shape({
//   address: Yup.string().required('Address is required'),
//   postalCode: Yup.string().required('Postal code is required'),
//   city: Yup.string().required('City is required'),
//   state: Yup.string().required('State is required'),
// });

interface SetupFormProps {
  setAddPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetupForm = forwardRef<SetupFormHandle, SetupFormProps>(({ setAddPaymentMethod }, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const { profile } = useContext(AuthContext);

  const baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');

  console.log('baseUrl', `${baseUrl}/profile/billing`);

  const [isDoingPayment, setDoingPayment] = useState(false);

  useImperativeHandle(ref, () => ({
    handleSubmit: handleAddPaymentMethod,
  }));

  // const formInputs = [
  //   {
  //     name: 'address',
  //     label: 'Address line',
  //     placeholder: 'Enter address line',
  //     disabled: false,
  //     textArea: false,
  //     multiSelect: false,
  //   },
  //   {
  //     name: 'postalCode',
  //     label: 'Postal code',
  //     placeholder: 'Enter postal code',
  //     disabled: false,
  //     textArea: false,
  //     multiSelect: false,
  //   },
  //   {
  //     name: 'city',
  //     label: 'City',
  //     placeholder: 'Enter city',
  //     disabled: false,
  //     textArea: false,
  //     multiSelect: false,
  //   },
  //   {
  //     name: 'state',
  //     label: 'State',
  //     placeholder: 'Enter state',
  //     disabled: false,
  //     textArea: false,
  //     multiSelect: false,
  //   },
  // ];
  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     address: '',
  //     postalCode: '',
  //     city: '',
  //     state: '',
  //   },
  //   validationSchema,
  //   onSubmit: values => {
  //     console.log(values);
  //   },
  // });

  // const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;

  // const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
  //   setFieldValue(name, value);
  // };

  const handleAddPaymentMethod = async () => {
    setDoingPayment(true);
    // await formik.submitForm();
    // if (values.address && values.city && values.postalCode && values.state) {
      if (!stripe || !elements) {
        console.log('no stripe - no element');
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return null;
      }

      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${baseUrl}/billing`,
          payment_method_data: {
            billing_details: {
            //   address: {
            //     city: values.city,
            //     line1: values.address,
            //     line2: '',
            //     postal_code: values.postalCode,
            //     state: values.state,
            //   },
              email: profile?.email,
              name: `${profile?.firstName} ${profile?.lastName}`,
              phone: profile?.phone,
            },
          },
        },
      });

      if (error) {
        setDoingPayment(false);
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        console.log('error >>>> ', error);
        // setErrorMessage(error.message);
      } else {
        setDoingPayment(false);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    // }
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleAddPaymentMethod();
        }}
      >
        <PaymentElement />
        <div className="mt-6">
          {/*<div className="grid grid-cols-12 gap-6">*/}
          {/*  {formInputs.map((sub, i) => (*/}
          {/*    <div className={`col-span-6 w-full`} key={i}>*/}
          {/*      <InputText*/}
          {/*        id={sub.name}*/}
          {/*        name={sub.name}*/}
          {/*        label={sub.label}*/}
          {/*        placeholder={sub.placeholder}*/}
          {/*        value={values[sub.name as keyof BillingInformation]}*/}
          {/*        onChange={onChangeHandler}*/}
          {/*        isDisabled={sub.disabled}*/}
          {/*        isError={*/}
          {/*          touched[sub.name as keyof BillingInformation] &&*/}
          {/*          !!errors[sub.name as keyof BillingInformation]*/}
          {/*        }*/}
          {/*        errorMessage={errors[sub.name as keyof BillingInformation]}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</div>*/}
          <div className="flex items-center gap-4 justify-end mt-6">
            <Button
              size="small"
              color="primary"
              label={
                <p
                  className="uppercase text-[#4A4A4A] font-medium font-inter text-lg leading-[21.6px] cursor-pointer"
                  onClick={() => setAddPaymentMethod(false)}
                >
                  Cancel
                </p>
              }
              className="px-8 !bg-transparent border-2 rounded-[5px] w-[175px] border-[#4A4A4A]"
            />
            <Button
              size="small"
              color="primary"
              type="submit"
              label={
                <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                  Save
                </p>
              }
              className="px-10  w-[175px]"
            />
          </div>
        </div>
      </form>

      {isDoingPayment && <LoadingSpinner />}
    </>
  );
});

export default SetupForm;
