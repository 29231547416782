import { CompanyAvatar, CompanyImgPlaceholder } from "src/components/common";

interface Props {
  url?: string;
}

export default function CompanyImage({ url }: Props) {
  return (
    <>
      {url ? <CompanyAvatar url={url} size="2xl" /> : <CompanyImgPlaceholder size="x-lg" />}
    </>
  );
}
