import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { AuthContextProvider, NotificationProvider } from 'src/contexts';

import AppRouter from './routes/AppRouter';
import NotificationsWrapper from './components/common/ui/notifications-wrapper/NotificationsWrapper';

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string;

export default function App() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get('error');

    if (error === 'access_denied') {
      navigate('/sign-up/borrower', {
        replace: true,
        state: { emailNotification: true },
      });
    }
  }, [searchParams]);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={(appState?: AppState) => {
        navigate(appState?.returnTo || '/', { replace: true });
      }}
      cacheLocation="localstorage"
    >
      <AuthContextProvider>
        <NotificationsWrapper>
          <NotificationProvider>
            <AppRouter />
          </NotificationProvider>
        </NotificationsWrapper>
      </AuthContextProvider>
    </Auth0Provider>
  );
}
