import { useContext, useEffect, useState } from 'react';
import { useHttp } from 'src/hooks';
import { Button, IconSvg } from 'src/components/common';
import { LoadingSpinner } from '../common/ui/loading-spinner/LoadingSpinner';

interface Props {
  onSubmitHandler: (id: string, amount: string | number, title:string, withoutPriceId:string) => void;
}

export function Plans({ onSubmitHandler }: Props) {
  const { sendRequest, isLoading } = useHttp();
  const [prices, setPrices] = useState<any>([]);

  const [selectedPlan, setSelectedPlan] = useState<string>('');

  const getCompanyPaymentMethods = () => {
    sendRequest(
      {
        url: 'payment/prices',
        method: 'GET',
      },
      (data: any) => {
        setPrices(data);
      },
    );
  };

  useEffect(() => {
    getCompanyPaymentMethods();
  }, []);

  const plans = [
    {
      id: prices[0]?.priceId,
      withoutPriceId:prices[0]?.id,
      title: 'BIANNUAL',
      description1: prices[0]?.details[0]?.title || '',
      description2: prices[0]?.details[1]?.title || '',
      description3: prices[0]?.details[2]?.title || '',
      description4: prices[0]?.details[3]?.title || '',
      currentPlan: false,
      usd: prices[0]?.amount ? parseInt(prices[0]?.amount, 10) : '',
      savePer: '',
      button: selectedPlan === prices[0]?.priceId ? 'Current plan' : 'Choose plan',
      cancelPlan: false,
      modalDescriptionText:
        'Are you sure about switching from a yearly to a biannual subscription?',
      modalTitle: 'Biannual Subscription',
    },
    {
      id: prices[1]?.priceId,
      withoutPriceId:prices[1]?.id,
      title: 'YEARLY',
      description1: prices[1]?.details[0]?.title || '',
      description2: prices[1]?.details[1]?.title || '',
      description3: prices[1]?.details[2]?.title || '',
      description4: prices[1]?.details[3]?.title || '',
      currentPlan: true,
      usd: prices[1]?.amount ? parseInt(prices[1]?.amount, 10) : '',
      savePer: '25',
      button: selectedPlan === prices[1]?.priceId ? 'Current plan' : 'Choose plan',
      cancelPlan: false,
      modalDescriptionText:
        'Yay! Switching to an yearly subscription will save you 25% in membership costs. Ready?',
      modalTitle: 'Annual Subscription',
    },
  ];
  return (
    <>
      <h3 className="lg:text-xl text-lg font-inter font-bold text-[#0F0F0F] lg:leading-6 leading-[21.6px] ">
        Welcome to XPLORIUS.
      </h3>
      <h6 className="lg:text-base sm:text-sm text-sm font-light mt-1 text-[#4A4A4A] font-inter">
        Sign up in a minute and unlock unlimited hotel deals.
      </h6>

      <h3 className="text-base font-bold font-inter text-[#0F0F0F] leading-[19.2px] mt-6">
        Step 1: Pick your membership
      </h3>
      <div className="grid lg:grid-cols-12 grid-cols-1 gap-8 mt-6">
        {plans.map((plan, i) => (
          <div
            className={`lg:col-span-6 ${'border-[2px]  rounded-[5px]'} ${
              selectedPlan == plan.id ? 'border-[#C56B48]' : 'border-[#C7C7C7]'
            } flex flex-col justify-between  w-full relative`}
            key={plan.id}
          >
            <div>
              <div
                className={`flex justify-center items-center ${
                  i == 2 ? '!bg-[#4A4A4A] border-white border-b' : 'bg-[#C56B48]'
                }`}
              >
                <p className="uppercase py-6 text-[#EFE8DF] text-2xl font-medium font-inter leading-[28.8px]">
                  {plan.title}
                </p>
              </div>
              <div className="p-3 flex flex-col">
                <div className="flex items-start gap-4 pt-4">
                  <IconSvg icon={'oDotSmallOrange'} />
                  <p
                    className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                  >
                    {plan.description1}
                  </p>
                </div>
                <div className="flex items-start gap-4 pt-4">
                  <IconSvg icon={'oDotSmallOrange'} />
                  <p
                    className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                  >
                    {plan.description2}
                  </p>
                </div>
                <div className="flex items-start gap-4 pt-4">
                  <IconSvg icon={'oDotSmallOrange'} />
                  <p
                    className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                  >
                    {plan.description3}
                  </p>
                </div>
                <div className="flex items-start gap-4 pt-4">
                  <IconSvg icon={i == 2 ? 'oDotWhiteSmall' : 'oDotSmallOrange'} />
                  <p
                    className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                  >
                    {plan.description4}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center p-3 mt-4">
              <div className="flex items-baseline gap-2">
                <h1 className="pb-5 text-[#0F0F0F]  text-[28px] font-inter font-light leading-[33.6px]">
                  {plan.usd} USD
                </h1>
                {plan.savePer && (
                  <p className="text-[#EFE8DF]  text-base font-inter font-bold leading-[19.2px] bg-[#4A4A4A] px-2.5 py-1.5 rounded-[10px]">{`(SAVE ${plan.savePer}%)`}</p>
                )}
              </div>

              <Button
                size="small"
                color="primary"
                label={
                  <p className="uppercase text-[#EFE8DF] font-medium font-inter sm:text-lg text-base leading-[21.6px]">
                    {plan.button}
                  </p>
                }
                className="lg:px-16 sm:px-[35px] px-16"
                onClick={() => {
                  setSelectedPlan(plan.id);
                  onSubmitHandler(plan.id, plan.usd, plan.title, plan.withoutPriceId);
                }}
              />
            </div>
            {i == 1 && (
              <div className="absolute -top-5 right-4 flex items-center gap-2 bg-[#9B2626] py-2 px-3 rounded-[5px]">
                <IconSvg icon="starCream" />
                <p className="font-light font-inter text-sm text-[#EFE8DF] uppercase">
                  BEST VALUE{' '}
                </p>
              </div>
            )}
          </div>
        ))}
        {isLoading && <LoadingSpinner />}
      </div>
    </>
  );
}
