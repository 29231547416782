import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconSvg } from 'src/components/common';
import { InputText } from 'src/components/common/form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHttp, useNotification } from '../../../hooks';
import { AuthContext } from '../../../contexts';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';

interface passwordForm {
  password: string;
  confirmPassword: string;
}
const passwordRules =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRules,
      'Password must be at least 8 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export function ResetPassword() {
  const navigate = useNavigate();
  const { accessToken, setAccessToken, userAuth0 } = useContext(AuthContext);
  const { sendRequest: apiRequestHandler, isLoading: isLoading, error } = useHttp();
  const location = useLocation();
  const notify = useNotification();

  const formik = useFormik<passwordForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: values => {
      updatePassword(values);
    },
  });

  const { values, errors, setFieldValue, handleSubmit, touched, resetForm } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };

  const formInputs = [
    {
      name: 'password',
      label: '',
      placeholder: 'Password',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'confirmPassword',
      label: '',
      placeholder: 'Confirm Password',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
  ];
  const updatePassword = (payload: passwordForm) => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    apiRequestHandler(
      {
        method: 'POST',
        url: `auth/reset-password`,
        accessToken,
        payload: {
          encryptedData: token,
          password: payload.password,
        },
      },
      (data: any) => {
        notify(`${data.message}`, 'success');
        resetForm();
        navigate('/login')
        // if (localStorage.getItem('::@@xploriusJwtAuthspajs@@::')) {
        //   navigate('/booking');
        // } else {
        //   navigate('/login');
        // }
      },
    );
  };
  useEffect(() => {
    if (error) {
      notify(error || '', 'error');
      resetForm();
    }
  }, [error]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
  }, [location.search]);
  return (
    <>
      <div className=" bg-cover bg-center h-full w-full lg:bg-auth-background sm:bg-auth-background-small bg-none flex justify-center items-center">
        <div className="lg:w-[480px] sm:w-[448px] sm:h-auto h-full w-full bg-[#EFE8DF] rounded-[5px] sm:p-6 p-2.5">
          <form onSubmit={handleSubmit}>
            <div className="pb-4 px-1.5">
              <div className="flex flex-col justify-center items-center">
                <IconSvg
                  icon="logoBlack"
                  className="w-[148.78px] h-[36.16px] cursor-pointer"
                  onClick={() => navigate('/')}
                />
                <div className="mt-10 mb-[30px]">
                  <p className="font-bold text-xl text-[#0F0F0F] font-inter leading-6">
                    Update Your Password
                  </p>
                </div>
              </div>

              <div className="mb-5">
                <InputText
                  id="password"
                  name="password"
                  label=""
                  placeholder="Password"
                  value={values.password}
                  onChange={onChangeHandler}
                  isError={touched.password && !!errors.password}
                  errorMessage={touched.password && errors.password ? errors.password : undefined}
                  type="password"
                  icon="eye"
                />
              </div>

              <div className="mb-5">
                <InputText
                  id="confirmPassword"
                  name="confirmPassword"
                  label=""
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChange={onChangeHandler}
                  isError={touched.confirmPassword && !!errors.confirmPassword}
                  errorMessage={
                    touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : undefined
                  }
                  type="password"
                  icon="eye"
                />
              </div>

              <div className="flex justify-end">
                <Button
                  size="medium"
                  type="submit"
                  color="yellow"
                  label="Update Password"
                  className="w-full"
                />
              </div>
            </div>
          </form>
          <p
            className="font-light font-inter  text-base cursor-pointer text-center sm:mt-5 mt-6 leading-[19.2px]"
            onClick={() => navigate('/login')}
          >
            <span className="font-medium font-inter">Return to login</span>
          </p>
        </div>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
}
