  import { useNavigate } from 'react-router-dom';
  import { Button, IconSvg, Modal } from 'src/components/common';

  interface Props {
    isOpen: boolean;
    img: string;
    onCancel: () => void;
  }

  export function XploriusRiskFreeModal({ isOpen, onCancel, img }: Props) {
    
    const navigate = useNavigate();
    return (
      <Modal
        isStatic={window.innerWidth < 768 ? true : false}
        isOpen={isOpen}
        close={onCancel}
        loading={false}
        modalWidth="lg:w-[978px] sm:w-[600px] lg:h-[310px] h-auto w-full !p-0 !m-0"
        modalBgClassName='!bg-black !opacity-[0.7] !backdrop-blur-[3px]'
      >
        <>
          <div className="lg:block hidden">
            <>
              <div className="flex items-start gap-2 w-full">
                <div className="w-full pl-[30px] pt-[30px]">
                  <p className="font-bold text-2xl leading-[29.05px] font-inter text-[#0F0F0F]">
                    Enjoy XPLORIUS risk-free!
                  </p>
                  <div className="w-[437px] mt-6">
                    <p className="font-light text-base leading-[20.8px] font-inter text-[#4A4A4A]">
                      If you decide within the first 24 hours of signing up that <br />we are not the right
                      fit for you, we will offer you a full refund.
                    </p>
                    <p className="font-normal text-base font-inter leading-[20.8px] italic text-[#4A4A4A] mt-6">
                      Please note that membership fees cannot be refunded if a booking has already
                      been made.
                    </p>
                  </div>
                  <div className="flex items-center gap-4 mt-6 w-full">
                    <div className="w-full">
                      <Button
                        size="small"
                        color="primary"
                        type="submit"
                        label={
                          <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.78px]">
                            I&apos;M NOT READY YET
                          </p>
                        }
                        className="w-full rounded-[5px] outline-none border-0"
                        onClick={onCancel}
                      />
                    </div>
                    <div className="w-full">
                      <Button
                        size="small"
                        color="orange"
                        type="submit"
                        label={
                          <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.78px]">
                            SIGN UP NOW!
                          </p>
                        }
                        className="w-full rounded-[5px] outline-none border-0"
                        onClick={() => navigate('/sign-up')}
                      />
                    </div>
                  </div>
                </div>
                <div className="relative w-full h-full flex items-start justify-end">
                  {/* <IconSvg icon="muslimGirlWithHatPng" className="w-[447.31px]" /> */}
                  <img src={img} className="w-[447.31px] h-[310px]" loading='lazy' />
                  <div className="absolute right-[30px] top-[30px]">
                    <IconSvg icon="cross" className="cursor-pointer" onClick={onCancel} />
                  </div>
                </div>
              </div>
            </>
          </div>

          {/* This section for tablet start */}
          <div className="lg:hidden sm:block hidden h-full">
            <>
              <div className="flex justify-between items-center px-[30px] pt-[30px]">
                <p className="font-bold text-xl leading-[24.2px] font-inter text-[#0F0F0F]">
                  Enjoy XPLORIUS risk-free!
                </p>
                <div className="">
                  <IconSvg
                    icon="cross"
                    className="cursor-pointer w-[13.18px] h-[13.5px]"
                    onClick={onCancel}
                  />
                </div>
              </div>
              <div className="flex justify-between items-start pl-[30px] ">
                <div className="mt-[21px]">
                  <div className=" w-[317px]">
                    <p className="font-light text-base leading-[20.8px] font-inter text-[#4A4A4A]">
                      If you decide within the first 24 hours of signing up that we are not the right
                      fit for you, we will offer you a full refund.
                    </p>
                    <p className="font-normal text-base font-inter leading-[20.8px] italic text-[#4A4A4A] mt-4">
                      Please note that membership fees cannot be refunded if a booking has already
                      been made.
                    </p>
                  </div>
                  <div className="flex items-center mt-[21px] gap-4 w-full">
                    <div className="w-full">
                      <Button
                        size="small"
                        color="primary"
                        type="submit"
                        label={
                          <p className="uppercase text-[#FFFFFF] font-medium font-inter text-base leading-[19.36px]">
                            I&apos;M NOT READY YET
                          </p>
                        }
                        className="!px-0 rounded-[5px] w-[175px] outline-none border-0"
                        onClick={onCancel}
                      />
                    </div>
                    <div className="w-full">
                      <Button
                        size="small"
                        color="orange"
                        type="submit"
                        label={
                          <p className="uppercase text-[#FFFFFF] font-medium font-inter text-base leading-[19.36px]">
                            SIGN UP NOW!
                          </p>
                        }
                        className="!px-0 rounded-[5px] w-[175px] outline-none border-0"
                        onClick={() => navigate('/sign-up')}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-[4px]">
                  <IconSvg icon="muslimGirlWithHatForTablet" className="w-auto" />
                </div>
              </div>
            </>
          </div>
          {/* This section for tablet end */}

          {/* This section for mobile start */}

          <div className="sm:hidden block">
            <div className="pt-4">
              <div className="flex items-center justify-between px-4">
                <p className="font-bold text-sm leading-[16.94px] font-inter text-[#0F0F0F]">
                  Enjoy XPLORIUS risk-free!
                </p>
                <div className="">
                  <IconSvg
                    icon="cross"
                    className="w-[12px] h-[12.29px] cursor-pointer"
                    onClick={onCancel}
                  />
                </div>
              </div>
              <div className="mt-[21px] px-4">
                <p className="font-light text-xs leading-[15.6px] font-inter text-[#4A4A4A]">
                  If you decide within the first 24 hours of signing up that we are not the right fit
                  for you, we will offer you a full refund.
                </p>
                <p className="font-normal text-xs font-inter leading-[15.6px] italic text-[#4A4A4A] mt-5">
                  Please note that membership fees cannot be refunded if a booking has already been
                  made.
                </p>
              </div>
              <div className=" flex items-center justify-between pl-4 gap-[17px]">
                <div className='w-full'>
                <Button
                    size="small"
                    color="orange"
                    type="submit"
                    label={
                      <p className="uppercase text-[#FFFFFF] font-medium font-inter text-xs leading-[14.4px]">
                        SIGN UP NOW!
                      </p>
                    }
                    className="!px-0 rounded-[5px] w-full outline-none border-0 border-collapse"
                    onClick={() => navigate('/sign-up')}
                  />
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    label={
                      <p className="uppercase text-[#FFFFFF] font-medium font-inter text-xs leading-[14.4px]">
                        I&apos;M NOT READY YET
                      </p>
                    }
                    className="!px-0 rounded-[5px] w-full outline-none border-0 mt-2.5"
                    onClick={onCancel}
                  />
                </div>
                <div className="w-full">
                  <IconSvg icon="muslimGirlWithHatForMobile" />
                </div>
              </div>
            </div>
          </div>
          {/* This section for mobile end */}
        </>
      </Modal>
    );
  }
