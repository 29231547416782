import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, InputText } from '../common/form';
import { Button } from '../common';
import { Elements } from '@stripe/react-stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { useHttp, useNotification } from 'src/hooks';
import { useContext, useEffect, useState } from 'react';
import { LoadingSpinner } from '../common/ui/loading-spinner/LoadingSpinner';
import { AuthContext } from '../../contexts';

const validationSchema = Yup.object().shape({
  // address: Yup.string().required('Address is required'),
  // postalCode: Yup.string().required('Postal code is required'),
  // city: Yup.string().required('City is required'),
  // state: Yup.string().required('State is required'),
  voucherNo: Yup.string().optional(),
});

interface voucherForm {
  // address: string;
  // postalCode: string;
  // city: string;
  // state: string;
  voucherNo: string;
}
interface Props {
  setCouponDiscount: (discount: string) => void;
  onSubmitHandler: (payload: voucherForm) => void;
  onAddCouponHandler: (payload: { value: string; type: string }) => void;
  finalData: any;
  planName: string;
}

export function VoucherDetails({
  onSubmitHandler,
  onAddCouponHandler,
  finalData,
  setCouponDiscount,
  planName,
}: Props) {
  const { sendRequest: apiRequestHandler, isLoading: isLoadingIntent } = useHttp();
  const stripe = useStripe();
  const elements = useElements();
  const [isDoingPayment, setDoingPayment] = useState(false);
  const [isValidCoupon, setValidCoupon] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState('');
  const notify = useNotification();
  const { accessToken, profile } = useContext(AuthContext);
  const [becomeMemberLoading, setBecomeMemberLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      if (!finalData) {
        finalData = {};
      }

      finalData.firstName = profile.firstName;
      finalData.lastName = profile.lastName;
      finalData.email = profile.email;
    }
  }, [profile]);

  const baseUrl =
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '');
  const formik = useFormik({
    initialValues: {
      // address: '',
      // postalCode: '',
      // city: '',
      // state: '',
      voucherNo: '',
    },
    validationSchema,
    onSubmit: async values => {
      // if (values.address && values.city && values.postalCode && values.state) {
      if (!stripe || !elements) {
        notify(`Stripe information not found`, 'error');

        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return null;
      }
      setBecomeMemberLoading(true);
      let return_url = `${baseUrl}/sign-up`;

      if (!!couponErrorMessage && !isValidCoupon) {
        notify(couponErrorMessage, 'error');
        setBecomeMemberLoading(false);
        return null;
      }

      if (isValidCoupon) {
        return_url = `${baseUrl}/sign-up?coupon=${values['voucherNo' as keyof voucherForm]}`;
      }

      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: return_url,
          payment_method_data: {
            billing_details: {
              // address: {
              //   city: values.city,
              //   line1: values.address,
              //   line2: '',
              //   postal_code: values.postalCode,
              //   state: values.state,
              // },
              email: finalData?.email,
              name: `${finalData?.firstName} ${finalData?.lastName}`,
              // phone: profile?.phone,
            },
          },
        },
      });

      if (error) {
        setDoingPayment(false);
        setBecomeMemberLoading(false);
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        console.log('error >>>> ', error);
        notify(error?.message ?? '', 'error');
        // setErrorMessage(error.message);
      } else {
        setBecomeMemberLoading(false);
        setDoingPayment(false);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        onSubmitHandler(values);
      }
      // }
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);

    if (name === 'voucherNo' && value) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      setDebounceTimer(
        setTimeout(() => {
          getCoupon(value);
        }, 1000),
      );
    }

    if (name === 'voucherNo' && !value) {
      setCouponDiscount('');
      setValidCoupon(false);
      setCouponErrorMessage('');
    }
  };

  const { sendRequest, isLoading: isLoading, error } = useHttp();

  useEffect(() => {
    if (error) {
      setBecomeMemberLoading(false);
    }
  }, [error]);
  const getCoupon = (id: string) => {
    sendRequest(
      {
        url: 'payment/coupon/' + id,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        if (data.valid) {
          if (data.percent_off !== null) {
            onAddCouponHandler({ value: `${data.percent_off}`, type: 'percent' });
          } else if (data.amount_off !== null) {
            onAddCouponHandler({ value: `${data.amount_off}`, type: 'amount' });
          }
        }
        setValidCoupon(data.valid);
        setCouponErrorMessage(data.message);
        finalData.couponValid = data.valid;
        finalData.couponMessage = data.message;
      },
    );
  };

  useEffect(() => {
    if (values.voucherNo) getCoupon(values.voucherNo);
  }, [planName]);

  return (
    <>
      {/* <Elements stripe={stripePromise} options={options}> */}
      <h3 className="text-xl font-inter font-bold text-[#0F0F0F] leading-6 ">
        Step 3: Card Details
      </h3>
      <h6 className="text-sm font-light mt-1 text-[#4A4A4A] font-inter">
        Please fill out your payment details.
      </h6>
      <form onSubmit={handleSubmit}>
        <div className="">
          <PaymentElement />
          {/*<div className="flex gap-5 w-full mt-4">*/}
          {/*  <div className="w-full">*/}
          {/*    <InputText*/}
          {/*      id={'address'}*/}
          {/*      name={'address'}*/}
          {/*      label={'Address line'}*/}
          {/*      placeholder={'Enter address'}*/}
          {/*      value={values['address']}*/}
          {/*      onChange={onChangeHandler}*/}
          {/*      //   isDisabled={item.disabled}*/}
          {/*      // wrapperStyles={styles['field-wrapper']}*/}
          {/*      isError={touched['address'] && !!errors['address']}*/}
          {/*      errorMessage={errors['address']}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="w-full">*/}
          {/*    <InputText*/}
          {/*      id={'postalCode'}*/}
          {/*      name={'postalCode'}*/}
          {/*      label={'Postal code'}*/}
          {/*      placeholder={'Enter postal code'}*/}
          {/*      value={values['postalCode']}*/}
          {/*      onChange={onChangeHandler}*/}
          {/*      //   isDisabled={item.disabled}*/}
          {/*      // wrapperStyles={styles['field-wrapper']}*/}
          {/*      isError={touched['postalCode'] && !!errors['postalCode']}*/}
          {/*      errorMessage={errors['postalCode']}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="my-4">*/}
          {/*  <InputText*/}
          {/*    id={'city'}*/}
          {/*    name={'city'}*/}
          {/*    label={'City'}*/}
          {/*    placeholder={'Enter city name'}*/}
          {/*    value={values['city']}*/}
          {/*    onChange={onChangeHandler}*/}
          {/*    //   isDisabled={item.disabled}*/}
          {/*    // wrapperStyles={styles['field-wrapper']}*/}
          {/*    isError={touched['city'] && !!errors['city']}*/}
          {/*    errorMessage={errors['city']}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="my-4">*/}
          {/*  <InputText*/}
          {/*    id={'state'}*/}
          {/*    name={'state'}*/}
          {/*    label={'State'}*/}
          {/*    placeholder={'Enter state name'}*/}
          {/*    value={values['state']}*/}
          {/*    onChange={onChangeHandler}*/}
          {/*    //   isDisabled={item.disabled}*/}
          {/*    // wrapperStyles={styles['field-wrapper']}*/}
          {/*    isError={touched['state'] && !!errors['state']}*/}
          {/*    errorMessage={errors['state']}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="my-4">
            <InputText
              id={'voucherNo'}
              name={'voucherNo'}
              label={'Voucher'}
              placeholder={'Enter voucher no'}
              value={values['voucherNo' as keyof voucherForm]}
              onChange={onChangeHandler}
              //   isDisabled={item.disabled}
              // wrapperStyles={styles['field-wrapper']}
              isError={!!couponErrorMessage && !isValidCoupon}
              isSuccess={!!couponErrorMessage && isValidCoupon}
              errorMessage={couponErrorMessage}
            />
          </div>
        </div>
        <div className="pb-10">
          <Button
            size="small"
            color="primary"
            label={
              <p className="uppercase text-[#EFE8DF] font-medium font-inter text-base leading-[21.6px]">
                Become a member
              </p>
            }
            type="submit"
            className="w-full mt-[21px]"
            isDisabled={becomeMemberLoading}
            onClick={() => {
              // setCurrentComponent(prev => prev + 1);
              // subscriptionModalHandler(plan.id);
              // setSelectedPlan(plan);
            }}
          />
        </div>
      </form>
      {/* </Elements> */}
      {isDoingPayment || (isLoading && <LoadingSpinner />)}
    </>
  );
}
