import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header, IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';

interface NftCollection {
  img: Icons;
}
export function TermsOfSale() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const termsOfUse = [
    {
      title: 'Introduction',
      p1: 'Welcome to XPLRS Group LLC-FZ. By purchasing a membership and accessing our platform, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.',
    },
    {
      title: 'Memberships',
      p1: '',
      listItems: [
        {
          listLine: 'Types of Memberships',
          listItemPoints: [
            'Annual Membership: Valid for 12 months.',
            'Biannual Membership: Valid for 6 months.',
          ],
        },
        {
          listLine: 'Membership Fees',
          listItemPoints: [
            'The price of a membership may change at any time.',
            'The price at which you purchase the membership applies only to the next 6 or 12 months, based on your membership choice.',
            'During your active membership period:',
            'If prices decrease, refunds are not applicable.',
            'If prices increase, you will not be required to pay the difference.',
          ],
        },
      ],
    },
    {
      title: 'Refund Policy',
      p1: '',
      listItems: [],
      listPoints: [
        'Refunds are available within the first 24 hours of membership purchase unless a booking has been made on the platform.',
        'Memberships purchased with a voucher cannot be refunded.',
      ],
    },
    {
      title: 'Automatic Renewal and Cancellation',
      p1: '',
      listItems: [],
      listPoints: [
        'Memberships automatically renew at the end of the term.',
        'Memberships will renew at the price associated with the type of membership relevant at the time of renewal. This price may be higher than your previous purchase.',
        'You may cancel your membership at any time to avoid automatic renewal.',
      ],
    },
    {
      title: 'Service Provision and Responsibility for Participating Properties',
      p1: '',
      listItems: [],
      listPoints: [
        'XPLRS Group aggregates offers from third-party providers and strives to find the best available rates but does not guarantee discounts.',
        'When you make a booking, you agree to pay the cost of the travel experience, including any applicable taxes and charges.',
        'Some prices may be rounded; the price you pay will be based on the original, non-rounded amount.',
      ],
      headingsWithPoints: [
        {
          heading: 'Policies',
          headingPoinst: [
            'By making a booking, you accept the applicable policies displayed during the booking process.',
            'Cancellation and no-show fees or refunds depend on the service provider’s policy.',
            'Some bookings cannot be canceled for free, while others can be canceled for free before a specified deadline.',
          ],
        },
        {
          heading: 'Payment',
          headingPoinst: [
            'If you book a travel experience and pay in advance, the service provider may cancel the booking without notice if they cannot collect the balance on the specified date. Refunds for non-refundable payments will be at the provider’s discretion.',
            'It is your responsibility to ensure timely payment, correct payment details, and sufficient funds.',
          ],
        },
        {
          heading: 'Late Arrivals',
          headingPoinst: [
            'If you anticipate being late, contact your service provider to inform them. Failure to do so may result in cancellation or additional fees, for which we are not responsible.',
          ],
        },
        {
          heading: 'Responsibilities',
          headingPoinst: [
            'As the person making the booking, you are responsible for the actions and behavior of everyone in your group.',
            'Ensure you have permission before providing us with others’ personal data.',
          ],
        },
        {
          heading: 'Participating Properties',
          headingPoinst: [
            'All deals, including those linked from our website, emailed offers, or displayed through any extensions, are priced and managed by the participating property or third-party service provider.',
            'You agree that all bookings made through a third-party property or service-provider are subject to the terms and conditions of the respective merchant. XPLRS Group LLC-FZ is not responsible or liable for any bookings made using our service. You acknowledge and agree that XPLRS Group LLC-FZ is not liable for any breaches of credit card or debit card security or privacy by any third-party properties.',
            'XPLRS Group LLC-FZ does not endorse, warrant, or guarantee the deals displayed through the service. We are not an agent, distributor, reseller, broker, or otherwise responsible for third-party properties or their activities or policies on their websites, or the products or services available on them. We do not guarantee that the deals offered by any particular advertiser or third party via our service are the best prices, best terms, or lowest rates available in the market.',
            'When you access third-party websites through our service, you accept the associated risks, and XPLRS Group LLC-FZ is not responsible for these risks. We encourage you to be aware when you are on a third-party property website and to read the terms and conditions and privacy policy of each third-party website or service you visit or utilize.',
            'XPLRS Group LLC-FZ has no control over and assumes no responsibility for the content, accuracy, privacy policies, practices, or opinions expressed in any third-party websites or by any third party you interact with through the service. We do not monitor, verify, censor, or edit the content of any third-party site or service.',
            'By using the service, you release and hold us harmless from any and all liability arising from your use of any third-party website or service. If you have a dispute with any third party (including a property from whom you make a booking in connection with the service), you agree that XPLRS Group LLC-FZ is under no obligation to become involved. To the maximum extent permitted by applicable law, you release XPLRS Group LLC-FZ, our affiliates, and each of their respective officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to any dispute you have with a third party in connection with the service.',
            'While we strive to facilitate the resolution of any potential issues that may arise, XPLRS Group LLC-FZ cannot be held liable for any issues related to bookings caused by third parties.',
          ],
        },
      ],
    },
    {
      title: 'Insurance',
      p1: '',
      listItems: [],
      listPoints: [
        'If you purchased insurance through our platform, refer to the policy documents for terms and conditions. These terms do not apply to insurance.',
      ],
    },
    {
      title: 'Communication and Booking Inquiries',
      p1: '',
      listItems: [],
      listPoints: [
        'For inquiries regarding bookings made with our partner properties, please contact them directly. Alternatively, our Customer Service team at XPLRS Group LLC-FZ is available to assist you. While we strive to resolve any concerns, please understand that XPLRS Group cannot be held liable for issues arising from the property or service provider.',
        'We may assist with communication between you and the service provider, but this does not transfer any responsibility for the travel experience or the provider’s actions to us.',
        'Contacting or being contacted by a service provider does not constitute grounds for legal action. If you need assistance, contact us via our Help Center.',
      ],
    },
    {
      title: 'Technology Disclaimer',
      p1: '',
      listItems: [],
      listPoints: [
        'Your access and use of the service may be interrupted from time to time due to third-party failures outside of our control (such as unavailability of general internet infrastructure). Access and use may also be interrupted for periodic updating, maintenance, or repair of the service by XPLRS Group LLC-FZ. While we will make reasonable efforts to make the service available to you, we do not guarantee that it will be available at all times',
      ],
    },
    {
      title: 'Limitation on Liability',
      p1: 'In no event will the company, its affiliates, or their licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your purchase or use, or inability to use, any product, including any direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract, or otherwise, even if foreseeable.',
      p2: 'The foregoing does not affect any liability which cannot be excluded or limited under applicable law.',
    },
    {
      title: 'Governing Law and Jurisdiction',
      p1: 'These Terms of Sale and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of Dubai, UAE without giving effect to any choice or conflict of law provision or rule (whether of Dubai, UAE or any other jurisdiction).',
      p2: 'Any legal suit, action, or proceeding arising out of, or related to, these Terms of Sale shall be instituted exclusively in the courts of Dubai, UAE, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Sale in your jurisdiction of residence or any other relevant jurisdiction. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.',
    },
    {
      title: 'Entire Agreement',
      p1: 'These Terms of Sale, along with our Terms of Use, Privacy Policy and Cookie Policy, constitute the entire and exclusive agreement between you and XPLRS Group LLC-FZ regarding your purchase of the product. This agreement supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, related to your purchase.',
    },
  ];

  return (
    <>
      <div className="w-full bg-[#EFE8DF] ">
        <Header />
        <div className="lg:px-10 px-2.5">
          <div className="sm:flex items-center justify-between mt-[72px]">
            <div className="flex items-center gap-2.5">
              <IconSvg
                icon="horizontalDoubleHeadLongArrow"
                className="lg:w-[152px] w-[77px] inline-block"
              />
              <h1 className="font-inter font-medium lg:text-6xl sm:text-3xl text-2xl lg:leading-[72px] sm:leading-9 leading-[28.8px] text-[#0F0F0F]">
                TERMS OF SALE
              </h1>
            </div>
            <p className="font-light font-inter lg:text-2xl sm:text-sm text-[12px] lg:leading-[28.8px] sm:leading-[16.8px] leading-[14.4px] sm:pt-0 pt-3">
              <span className="font-inter font-bold text-[#0F0F0F]">Last modified:</span> 1 June
              2024
            </p>
          </div>

          <div className="mt-[70x] lg:w-[861px] w-full">
            {termsOfUse.map((term, i) => (
              <>
                <div className="mt-[34px]">
                  <h4 className="font-inter font-bold lg:text-2xl sm:text-xl text-lg lg:leading-[28.8px] sm:leading-6 leading-[21.6px] text-[#0F0F0F]">
                    {term.title}
                  </h4>
                  {term.p1 && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p1}
                    </p>
                  )}
                  {term?.listItems &&
                    term?.listItems?.length >= 1 &&
                    term?.listItems?.map((listItem, i) => (
                      <>
                        <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A] mt-[34px]">
                          {listItem.listLine}
                        </p>
                        <ul className="list-disc pl-[29px]">
                          {listItem.listItemPoints.map((point, pointIndex) => (
                            <li
                              className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                              key={pointIndex}
                            >
                              {point}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  {term?.listPoints && (
                    <ul className="list-disc pl-[29px] mt-5">
                      {term?.listPoints?.length >= 1 &&
                        term.listPoints.map((listPoint, listPointIndex) => (
                          //   <div className="flex items-start" key={listPoint + listPointIndex}>
                          //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                          <li
                            className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                            key={listPoint + listPointIndex}
                          >
                            {listPoint}
                          </li>
                          //   </div>
                        ))}
                    </ul>
                  )}
                  {term?.headingsWithPoints &&
                    term?.headingsWithPoints?.length >= 1 &&
                    term.headingsWithPoints.map((heading, headingIndex) => (
                      <>
                        <h6 className="font-bold lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] font-inter text-[#4A4A4A] py-[24px]">
                          {heading.heading}
                        </h6>
                        {
                          <ul className="list-disc pl-[29px]">
                            {heading.headingPoinst.map((headingPoint, headingPointIndex) => (
                              //   <div className="flex items-start " key={headingPoint + headingPointIndex}>
                              //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                              <li
                                className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                                key={headingPoint + headingPointIndex}
                              >
                                {headingPoint}
                              </li>
                              //   </div>
                            ))}
                          </ul>
                        }
                      </>
                    ))}
                  {term.p2 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p2}
                    </p>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="lg:mt-[204px] sm:mt-[30px] mt-6 w-full">
          <div className="border-b border-[#4A4A4A]"></div>
          <div className="sm:mt-[46px] mt-6 lg:pb-[46px] lg:px-10 px-[15.5px]">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
