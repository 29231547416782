import { Button, IconSvg, Modal } from 'src/components/common';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

export function ResetPasswordConfirmationModal({ isOpen, onCancel }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      close={onCancel}
      loading={false}
      modalWidth="sm:w-[978px] sm:h-[336px] w-full !p-0 !m-0"
    >
      <>
        <div className="sm:block hidden">
          <>
            <div className="flex items-start gap-2 w-full">
              <div className="w-full pb-4  sm:px-9 px-4 sm:pt-5 pt-4">
                <p className="font-bold sm:text-2xl text-sm font-inter text-[#0F0F0F]">
                  Thank you for reaching out!
                </p>
                <div className="">
                  <p className="font-light text-base font-inter text-[#4A4A4A] pt-4">
                    Your inquiry has been successfully submitted.
                  </p>
                  <p className="font-light text-base font-inter text-[#4A4A4A] mt-3">
                    We&apos;ve just sent you an email to reset your password.
                  </p>
                  <div className="flex items-center gap-4 mt-4">
                    <Button
                      size="small"
                      color="primary"
                      type="submit"
                      label={
                        <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                          Done
                        </p>
                      }
                      className=""
                      onClick={onCancel}
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-full flex items-start">
                <IconSvg icon="helpRoundedLines" />
                <div className="absolute right-6 top-6">
                  <IconSvg
                    icon="cross"
                    className="sm:w-auto sm:h-auto w-[12px] h-[12.29px]"
                    onClick={onCancel}
                  />
                </div>
              </div>
            </div>
          </>
        </div>
        {/* This section for mobile start */}

        <div className="sm:hidden block relative">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <p className="font-bold  text-sm font-inter text-[#0F0F0F]">
                Thank you for reaching out!
              </p>
              <div className="absolute right-6 top-6">
                <IconSvg icon="cross" className="w-[12px] h-[12.29px] cursor-pointer" onClick={onCancel} />
              </div>
            </div>
            <div className="mt-[21px]">
              <p className="font-light text-[12px] font-inter text-[#4A4A4A]">
                Your inquiry has been successfully submitted.
              </p>
              <p className="font-light text-[12px] font-inter text-[#4A4A4A] mt-5">
                We&apos;ve just sent you an email to reset your password.
              </p>
            </div>
            <div className="">
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter text-[12px] leading-[14.4px]">
                    Done
                  </p>
                }
                className="w-[60px] h-[33px] mt-[21px]"
                onClick={onCancel}
              />
            </div>
          </div>
          <div className='absolute bottom-0 right-0'>
          <IconSvg icon="helpRoundedLinesMobile" />
          </div>
        </div>
        {/* This section for mobile end */}
      </>
    </Modal>
  );
}
