import { Button, IconSvg } from 'src/components/common';
import { TextArea } from 'src/components/common/form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import LogoControlPanel from 'src/components/help/logo-control-panel/LogoControlPanel';
import { HelpConfirmationModal } from 'src/components/help/Modal/HelpConfirmationModal';
import { useHttp } from 'src/hooks';
import { AuthContext } from 'src/contexts';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import styles from '../../../components/help/Help.module.scss'
import { InputText } from 'src/components/help/input-text/InputText';

export interface HelpInformation {
  name: string;
  email: string;
  subject: string;
  description: string;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  description: Yup.string().required('Description is required'),
});

export function Help() {
  const [imageUrl, setImageUrl] = useState('');
  const [logo, setLogo] = useState<File>();
  const [isOpenHelpConfimationModal, setIsOpenHelpConfimationModal] = useState(false);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const { accessToken, profile } = useContext(AuthContext);

  const logoAttachHendler = (file: File) => {
    console.log('file', file);
    setLogo(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const logoDeleteHendler = () => {
    console.log('test');
    setLogo(undefined);
    setImageUrl('');
    // sendRequest(
    //   {
    //     method: 'DELETE',
    //     url: `company/${profile?.account.company.id}/avatar`,
    //     accessToken,
    //   },
    //   fetchProfile,
    // );
  };

  const formInputs = [
    {
      name: 'email',
      label: 'Your email address',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'name',
      label: 'Name',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'subject',
      label: 'Subject',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: '',
      disabled: false,
      textArea: true,
      multiSelect: false,
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      subject: '',
      description: '',
    },
    validationSchema,
    onSubmit: values => {
      const formDataPayload = new FormData();
      if (logo) {
        formDataPayload.append('attachments', logo);
      }
      formDataPayload.append('name', values.name);
      formDataPayload.append('email', values.email);
      formDataPayload.append('subject', values.subject);
      formDataPayload.append('description', values.description);

      apiRequestHandler(
        {
          url: `help-request`,
          accessToken,
          method: 'POST',
          payload: formDataPayload,
        },
        (response: any) => {
          helpConfirmationModalHandler();
          resetForm();
          setLogo(undefined);
          setImageUrl('');
        },
      );
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched, resetForm } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };
  const helpConfirmationModalHandler = () => {
    setIsOpenHelpConfimationModal(prev => !prev);
  };

  return (
    <>
      <div className="">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Submit a request
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="sm:border  sm:border-[#959595] border-collapse rounded-[5px] lg:p-7 sm:p-6 p-0 lg:mt-5 sm:mt-3 mt-5">
            <div className="grid grid-cols-12">
              {formInputs.map((sub, i) => (
                <div className={`col-span-12 w-full ${i !== 0 ? 'mt-5' : ''}`} key={i}>
                  {sub?.textArea ? (
                    <div className="">
                      <TextArea
                        id={sub.name}
                        name={sub.name}
                        label={sub.label}
                        placeholder={sub.placeholder}
                        value={values[sub.name as keyof HelpInformation]}
                        onChange={onChangeHandler}
                        wrapperStyles=""
                        isError={
                          touched[sub.name as keyof HelpInformation] &&
                          !!errors[sub.name as keyof HelpInformation]
                        }
                        errorMessage={errors[sub.name as keyof HelpInformation]}
                      />
                    </div>
                  ) : (
                    <InputText
                      id={sub.name}
                      name={sub.name}
                      label={sub.label}
                      placeholder={sub.placeholder}
                      value={values[sub.name as keyof HelpInformation]}
                      onChange={onChangeHandler}
                      isDisabled={sub.disabled}
                      wrapperStyles={styles['field-wrapper']}
                      isError={
                        touched[sub.name as keyof HelpInformation] &&
                        !!errors[sub.name as keyof HelpInformation]
                      }
                      errorMessage={errors[sub.name as keyof HelpInformation]}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className=" mt-5">
              <p className="font-bold font-inter sm:text-xl text-sm sm:leading-6 leading-[16.8px] pb-[10px] text-[#0F0F0F]">
                Attachments (optional)
              </p>
              <LogoControlPanel
                imageUrl={imageUrl}
                isLogo={!!imageUrl}
                onImageAttached={logoAttachHendler}
                onDeleteLogo={logoDeleteHendler}
              />
            </div>
            <div className="flex items-start gap-4 mt-5">
              <Button
                size="small"
                color="yellow"
                type="submit"
                label={
                  <p className="uppercase text-[#0F0F0F] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Submit
                  </p>
                }
                className="px-10 lg:w-auto lg:h-[40px] w-full"
              />
            </div>
          </div>
        </form>

        <div className="flex lg:flex-row flex-col lg:items-center lg:pt-[60px] sm:pt-10 pt-[60px] lg:w-full bg-[#EFE8DF] lg:gap-10 ">
          <div className=" flex lg:justify-start justify-center  md:w-auto w-full " >
            {/* <IconSvg icon="oDotWIthsheepsBackground" /> */}
            <div className='lg:w-[271.99px] lg:h-[268.99px] w-[157px] h-[157px] bg-cover bg-center bg-no-repeat flex justify-center items-center rounded-full bg-[url(/public/assets/images/oDotWIthsheepsBackground.jpg)] lg:py-[12px] lg:px-[27px] py-[8px] px-[15px]'>
            <div className='bg-[#EFE8DF] lg:w-[265px] lg:h-[260px] w-[151px] h-[145px] rounded-[50%] flex justify-center items-center '>
              <div className='lg:w-[93.86px] lg:h-[93.11px] w-[54.18px] h-[54.34px] bg-cover bg-center bg-no-repeat rounded-full bg-[url(/public/assets/images/oDotWIthsheepsBackground.jpg)]'></div>
            </div>
            </div>
          </div>
          <div className="px-4">
            <p className="font-bold sm:text-[35px] text-[22px] lg:leading-[36px] sm:leading-[42.31px] lg:text-left text-center lg:mt-0 mt-5 lg:font-inter sm:font-bigCaslon text-[#4A4A4A] lg:normal-case sm:uppercase">
              At XPLORIUS, your journey is our priority.
            </p>
            <p className="font-medium lg:text-xl text-base lg:leading-[24px] leading-[19.2px] mt-5 text-[#4A4A4A] font-inter lg:text-start text-center lg:w-[690px]">
              Your insights, inquiries, and suggestions are invaluable to us. Feel free to reach out
              at any time &ndash;{' '}
              <span className="text-[#0F0F0F]">
                we&rsquo;re here to ensure your travel experience is nothing short of extraordinary.
              </span>
            </p>
          </div>
        </div>
      </div>
      <HelpConfirmationModal
        isOpen={isOpenHelpConfimationModal}
        onCancel={helpConfirmationModalHandler}
      />
      {isLoading && <LoadingSpinner />}
    </>
  );
}
