import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts';
import { LoadingSpinner } from '../components/common/ui/loading-spinner/LoadingSpinner';
// import { LoadingSpinner } from '../common/components';

type Props = {
  template: any;
};

export default function ProtectedRoutePolicy({ template }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { appConfig, userAuth0, profile, accessToken, isLoadingAuth0 } = useContext(AuthContext);
  console.log({ profile }, { userAuth0 });

  // useEffect(() => {
  //   if (!isLoadingAuth0) {
  //     console.log('userAuth0', userAuth0)
  //     console.log('userAuth0', isLoadingAuth0)
  //   }
  // }, [userAuth0, isLoadingAuth0]);

  // if user not logged in
  // if (!isLoadingAuth0 && !userAuth0) {
  //   return <Navigate to={`/`} replace />
  // }

  // if user account not created
  if (appConfig && !appConfig.isApproved) {
    return <Navigate to={`/sign-up`} replace />;
  }

  if (!accessToken) {
    navigate('/');
    return;
  }

  // if(appConfig?.user === "" || appConfig?.user?.account?.company === null) {
  //   return <Navigate to={`/company`} replace />
  // }

  // console.log('pathname', pathname)

  // if(appConfig?.user?.status === 'Deactivated')
  // return <Navigate to={`/deactivated`} />;

  // if(userAuth0?.sub){
  //   return template;
  // }else{
  //   navigate("/")
  // }
  if (appConfig) {
    // if survey is not completed then redirect user to home page instantly
    // if(!appConfig.isSurveyCompleted)
    // return <Navigate to={`/`} />;
    // // if member account is deactivated then redirect
    // if(appConfig.memberStatus === 'Deactivated' && pathname !== '/')
    //   return <Navigate to={`/deactivated`} />;

    // // if declined by Lender the show this page until he applied for Assetize
    // if (appConfig.status === 'Public' && (appConfig.tenantApproved === 'Pre-Rejected' || appConfig.tenantApproved === 'Rejected') && appConfig.joinAssetize === null) {
    //   if (appConfig.tenantApproved === 'Pre-Rejected')
    //     return <Navigate to={`/pre-declined`} />;
    //   else if (appConfig.tenantApproved === 'Rejected')
    //     return <Navigate to={`/declined`} />;
    //   // else
    //   //   return <Navigate to={`/approval`} />;
    // }

    // // if declined by admin as well then it should be blocked to this page
    // if (appConfig.status === 'Public' && appConfig.isApproved === false) {
    //   return <Navigate to={`/blocked`} />;
    // }

    // // if user is private and tenant approve is pending or public bit not apprived and also except the allowed routes then it should go to the approval page
    // // if(( (appConfig?.status === 'Private' && appConfig?.tenantApproved === 'Pending') || (appConfig?.status === 'Public' && appConfig?.isApproved === null) ) && pathname !== '/profile' && pathname !== '/get-started' && pathname !== '/verify-email') {
    // const isPrivatePendingApproval =
    //   appConfig?.status === 'Private' && appConfig?.tenantApproved === 'Pending';

    // const isPublicPendingApproval =
    //   appConfig?.status === 'Public' && appConfig?.isApproved === null;

    // const allowedRoutes = ['/profile', '/get-started', '/verify-email', '/transactions'];
    // const isAllowedRoute = allowedRoutes.includes(pathname);

    // if ((isPrivatePendingApproval || isPublicPendingApproval) && !isAllowedRoute) {
    //   return <Navigate to={`/approval`} replace />
    // }

    // // if role is manager or viewer then verification page is not allowed
    // if ((appConfig?.role === ERoles.VIEWER || appConfig?.role === ERoles.MANAGER) && pathname === '/verification')
    //   return <Navigate to={`/profile`} replace />

    // if all good return the template
    return template;
  } else {
    return <LoadingSpinner />;
  }
}
