import React, { Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';
import { AuthContext } from 'src/contexts';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface DropdownMenuProps {
  name?: string;
  options: DropdownMenuOption[];
  onChange: (value: string, name: string, path: string) => void;
  defaultValue?: string;
  background?: string;
  placeholder?: string;
  icon?: boolean;
  className?: string;
  placeHolderIcon?: ReactNode;
}

export interface DropdownMenuOption {
  name: string;
  value: string;
  icon: Icons;
  activeIcon: Icons;
  path: string;
  active: boolean;
}

export function MenuCard({
  name,
  className = '',
  options = [],
  defaultValue = '',
  background = '',
  onChange,
  icon = false,
  placeholder = 'Select item',
  placeHolderIcon,
}: DropdownMenuProps) {
  const location = useLocation();
  const [value, setValue] = useState(defaultValue);
  const [actionName, setActionName] = useState(defaultValue);
  const [planSubscriptionState, setPlanSubscriptionState] = useState(false);
  const { logout, accessToken, userAuth0, profile } = useContext(AuthContext);
  const { pathname } = useLocation();

  const handleItemClick = (value: string, name: string, path: string) => {
    if (name !== 'Sign out' && planSubscriptionState) return;
    onChange(value, name, path);
    setValue(value);
    setActionName(name);
  };

  const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent the event from propagating to the parent div
  };

  useEffect(() => {
    if (userAuth0?.sub && !profile?.account?.plan) {
      setPlanSubscriptionState(true);
    } else {
      setPlanSubscriptionState(false);
    }
  }, [userAuth0?.sub, profile?.account?.plan, pathname]);

  return (
    <div onClick={handleDropdownClick}>
      <Menu
        as="div"
        className={
          location.pathname.includes('watchlist')
            ? ' inline-block text-left'
            : 'relative inline-block text-left'
        }
      >
        <Menu.Button
          className={`${
            icon
              ? ''
              : `inline-flex w-full justify-center 
        ${
          background ? `bg-[${background}]` : 'bg-white'
        }  text-base font-normal text-[#000000A3] hover:bg-gray-50 focus:outline-none flex items-center justify-center`
          }`}
        >
          {icon ? (
            placeHolderIcon
          ) : (
            <div className="flex items-center justify-between h-[41px] w-[137px] bg-[#C56B48] px-2 rounded-[5px]">
              <div className="flex items-center  gap-2">
                <div className="h-[25px] w-[25px] flex justify-center items-center rounded-full bg-[#EFE8DF]">
                  {' '}
                  <IconSvg icon="user" />
                </div>
                <p className="font-inter text-base leading-[19.2px] font-medium text-[#EFE8DF] uppercase">
                  {placeholder}
                </p>
              </div>
              <IconSvg icon="downArrowHeadCream" />
            </div>
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`z-50 absolute ${'right-0'}  mt-2 origin-top-right rounded-[5px] bg-[#EFE8DF] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${className}`}
          >
            {options.map((item: DropdownMenuOption, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div>
                    <div
                      onClick={() => handleItemClick(item.value, item.name, item.path)}
                      className={classNames(
                        active ? 'bg-[#EFE8DF] text-gray-900 ' : 'text-gray-900 ',
                        `px-4 pt-4 ${index + 1 == options.length && 'pb-4'}  text-sm `,
                        `${
                          item.name !== 'Sign out' && planSubscriptionState
                            ? 'cursor-not-allowed'
                            : 'cursor-pointer'
                        }`,
                      )}
                    >
                      <div className="flex items-center gap-2.5">
                        <span>
                          {
                            <IconSvg
                              icon={item.active ? item.activeIcon : item.icon}
                              className={`h-[20px] w-[20px] ${
                                item.name !== 'Sign out' && planSubscriptionState
                                  ? '!cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            />
                          }
                        </span>
                        <div className="flex justify-between items-center">
                          <p
                            className={`text-sm font-light font-inter ${
                              item.active ? 'text-[#C56B48]' : 'text-[#000000]'
                            }`}
                          >
                            {item.name}
                          </p>
                          {/* {item.value == placeholder && <IconSvg icon="tickCheck" />} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
