import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header, IconSvg } from 'src/components/common';

export function CookiePolicy() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const termsOfUse = [
    {
      title: '',
      p1: 'Welcome to XPLRS Group LLC-FZ, referred to as XPLORIUS. Your privacy is important to us. To enhance your experience on our website, we use cookies and similar technologies. This Cookie Consent Form explains the types of cookies we use, why we use them, and how you can control your cookie preferences.',
    },
    {
      title: 'Types of Cookies We Use',
      p1: '',
      listItems: [],
      listPoints: [
        'Essential Cookies: These are necessary for the website to function and cannot be switched off. They are typically set in response to your actions such as setting your privacy preferences, logging in, or filling in forms.',
        'Analytics Cookies: We use Google Analytics to understand how our visitors interact with the website. These cookies help us to improve our website by collecting and reporting information on its usage.',
        'Performance Cookies: These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site.',
        'Functional Cookies: These are used to enable certain functionalities like the booking inquiry form, making it easier for you to use our services',
      ],
    },
    {
      title: 'Why We Use Cookies',
      p1: '',
      listItems: [],
      listPoints: [
        'To provide a seamless browsing experience.',
        'To analyze our website performance and usage patterns.',
        'To improve our content and services based on user feedback and usage data.',
      ],
    },
    {
      title: 'Your Choices',
      p1: '',
      listItems: [],
      listPoints: [
        'You have control over your cookie preferences. You can choose to accept all cookies, reject non-essential cookies. Please note that blocking some types of cookies may impact your experience on our site and the services we are able to offer.',
      ],
    },
    {
      title: 'How to Manage Cookies',
      p1: '',
      listItems: [],
      listPoints: [
        'Accept All Cookies: By clicking “Accept All,” you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.',
        'Reject Non-Essential Cookies: By choosing this option, only essential cookies will be used to provide you with necessary website functionalities.',
      ],
    },
    {
      title: 'Changes to Cookie Consent',
      p1: 'This form was last updated on December 18th, 2023.',
      p2: 'We may update our Cookie Consent Form from time to time. We encourage you to review this form periodically to be informed about how we are protecting your information',
      listItems: [],
      listPoints: [],
      afterListPointsLine: '',
    },
    {
      title: 'Contact Us',
      p3: 'If you have any questions about our use of cookies or this Cookie Consent Form, please contact us at',
    },
  ];

  return (
    <>
      <div className="w-full bg-[#EFE8DF] ">
        <Header />
        <div className="lg:px-10 px-2.5">
          <div className="sm:flex items-center justify-between mt-[72px]">
            <div className="flex items-center gap-2.5">
              <IconSvg icon="horizontalDoubleHeadLongArrow" className="lg:w-[152px] w-[77px] inline-block" />
              <h1 className="font-inter font-medium lg:text-6xl sm:text-3xl text-2xl lg:leading-[72px] sm:leading-9 leading-[28.8px] text-[#0F0F0F]">
                COOKIE POLICY
              </h1>
            </div>
            <p className="font-light font-inter lg:text-2xl sm:text-sm text-[12px] lg:leading-[28.8px] sm:leading-[16.8px] leading-[14.4px] sm:pt-0 pt-3">
              <span className="font-inter font-bold text-[#0F0F0F]">Last modified:</span> 1
              June 2024
            </p>
          </div>

          <div className="mt-[70x] lg:w-[861px] w-full">
            {termsOfUse.map((term, i) => (
              <>
                <div className="mt-[34px]">
                  {term.title && (
                    <h4 className="font-inter font-bold lg:text-2xl sm:text-xl text-lg lg:leading-[28.8px] sm:leading-6 leading-[21.6px] text-[#0F0F0F]">
                      {term.title}
                    </h4>
                  )}
                  {term.p1 && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p1}
                    </p>
                  )}
                  {term?.listPoints && (
                    <ul className="list-disc pl-[29px] mt-5">
                      {term?.listPoints?.length >= 1 &&
                        term.listPoints.map((listPoint, listPointIndex) => (
                          //   <div className="flex items-start" key={listPoint + listPointIndex}>
                          //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                          <li
                            className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                            key={listPoint + listPointIndex}
                          >
                            {listPoint}
                          </li>
                          //   </div>
                        ))}
                    </ul>
                  )}
                  {term.afterListPointsLine && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]">
                      {term.afterListPointsLine}{' '}
                      <span
                        className="font-inter font-bold text-[#C56B48] border-b inline-block border-[#C56B48] cursor-pointer "
                        onClick={() => navigate('/')}
                      >
                        hi@xplorius.com
                      </span>
                    </p>
                  )}
                  {term.p2 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p2}
                    </p>
                  )}
                  {term.p3 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p3}{' '}
                      <a
                        href="mailto:hi@xplorius.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <span
                          className="font-inter font-bold text-[#C56B48] border-b inline-block border-[#C56B48] cursor-pointer "
                          // onClick={() => navigate('/')}
                        >
                          hi@xplorius.com
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="lg:mt-[204px] sm:mt-[30px] mt-6 w-full">
          <div className="border-b border-[#4A4A4A]"></div>
          <div className="sm:mt-[46px] mt-6 lg:pb-[46px] lg:px-10 px-[15.5px]">
            <Footer />
          </div>
        </div>
      </div>

    </>
  );
}
