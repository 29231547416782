import { useEffect, useState } from 'react';
import { Button, IconSvg, Modal } from 'src/components/common';
import { Checkbox } from 'src/components/common/form';
import { useHttp } from 'src/hooks';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSubmitHandler: (selectedPlans: string[]) => void;
  couponId: string;
}

export function PlansCouponModal({ isOpen, onCancel, onSubmitHandler, couponId }: Props) {
  const [plans, setPlans] = useState<any>([]);
  const [selectedPlans, setSelectedPlans] = useState<string[]>([]);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();

  const getPlansHandler = () => {
    apiRequestHandler(
      {
        url: 'payment/prices',
        method: 'GET',
      },
      (data: any) => {
        setPlans(data);
      },
    );
  };

  useEffect(() => {
    getPlansHandler();
  }, []);

  const plansOptions = [
    {
      label: 'BIANNUAL',
      name: 'biannual',
      value: plans[0]?.id,
      amount: plans[0]?.amount ? parseInt(plans[0]?.amount, 10) : 0,
    },
    {
      label: 'YEARLY',
      name: 'yearly',
      value: plans[1]?.id,
      amount: plans[1]?.amount ? parseInt(plans[1]?.amount, 10) : 0,
    },
  ];
  const handlePlansCheckboxChange = (name: string, value: string) => {
    setSelectedPlans(prevSelectedPlans =>
      prevSelectedPlans.includes(value)
        ? prevSelectedPlans.filter(planValue => planValue !== value)
        : [...prevSelectedPlans, value],
    );
  };

  const handleSubmit = () => {
    onSubmitHandler(selectedPlans);
    setSelectedPlans([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      close={onCancel}
      loading={false}
      modalWidth="sm:w-[691px] sm:h-[285px] w-full h-[152px] !p-0 !m-0"
    >
      <>
        <div className="sm:pb-4 pb-4  sm:px-9 px-4 sm:pt-5 pt-4">
          <>
            <div className="flex justify-between sm:items-start items-center gap-4 w-full">
              <p className="font-bold sm:text-2xl text-sm font-inter text-[#0F0F0F]">
                Add plans in coupon
              </p>

              <IconSvg
                icon="cross"
                className="sm:w-auto sm:h-auto w-[12px] h-[12.29px] cursor-pointer"
                onClick={onCancel}
              />
            </div>
          </>
        </div>
        <div className="relative sm:block hidden">
          <div className="w-[400px] pl-8">
            <div>
              {/* <p className="font-inter sm:text-base text-sm leading-6 font-medium text-[#4A4A4A]">Plans</p> */}
              {plansOptions.map((plan, index: number) => (
                <div className="pt-[18px]" key={index}>
                  <Checkbox
                    id={plan.name}
                    checked={selectedPlans.includes(plan.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePlansCheckboxChange(e.target.name, plan.value)
                    }
                    name={plan.name}
                    label={plan.label}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter text-lg leading-[21.6px]">
                    Add
                  </p>
                }
                className={'w-full'}
                onClick={handleSubmit}
              />
              <Button
                size="small"
                color="primary"
                label={
                  <p className="uppercase text-[#4A4A4A] font-medium font-inter text-lg leading-[21.6px]">
                    Cancel
                  </p>
                }
                className="w-full !bg-transparent border rounded-[5px] border-[#4A4A4A]"
                onClick={onCancel}
              />
            </div>
          </div>
          <div className="absolute top-0 right-0 -z-10">
            <IconSvg icon="randomLinesBgGreen" />
          </div>
        </div>

        {/* This section for mobile start */}
        <div className="sm:hidden block px-4">
          <div className="">
            <div>
              {/* <p className="font-inter sm:text-base text-sm leading-6 font-medium text-[#4A4A4A]">Plans</p> */}
              {plansOptions.map((plan, index: number) => (
                <div className="pt-[18px]" key={index}>
                  <Checkbox
                    id={plan.name}
                    checked={selectedPlans.includes(plan.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePlansCheckboxChange(e.target.name, plan.value)
                    }
                    name={plan.name}
                    label={plan.label}
                  />
                </div>
              ))}
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Add
                  </p>
                }
                className={'w-full h-[33px]'}
                onClick={() => onSubmitHandler(selectedPlans)}
              />
              <Button
                size="small"
                color="primary"
                label={
                  <p className="uppercase text-[#4A4A4A] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Cancel
                  </p>
                }
                className="w-full !bg-transparent border rounded-[5px] border-[#4A4A4A] h-[33px]"
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
        {/* This section for mobile end */}
      </>
    </Modal>
  );
}
