import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header, IconSvg } from 'src/components/common';
import { Icons } from 'src/types/icons';

export function PrivacyPolicy() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  const termsOfUse = [
    {
      title: '',
      p1: 'Welcome to XPLRS Group LLC-FZ, referred to as XPLORIUS. As a UAE-based company, we respect your privacy and are committed to protecting your personal data in compliance with UAE laws and regulations. This privacy policy will inform you about how we handle your personal data when you visit our website and your privacy rights under UAE law.',
    },
    {
      title: 'Important Information and Who We Are',
      p1: 'XPLRS Group FZ-LLC, based in the United Arab Emirates, is the controller and responsible for your personal data (collectively referred to as "XPLORIUS", "we", "us", or "our" in this privacy policy).',
      listItems: [
        {
          listLine: 'Contact Details:',
          listItemPoints: [
            'Full name of legal entity: XPLRS Group LLC-FZ',
            'Email address: hi@xplorius.com',
            'Postal address: Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab Emirates',
            'Telephone number: +971 58 565 7715',
          ],
        },
      ],
    },
    {
      title: 'The Data We Collect About You',
      p1: '',
      listItems: [],
      listPoints: [
        'We may collect, use, store, and transfer different kinds of personal data about you, including but not limited to Identity Data, Contact Data, Transaction Data, Technical Data, Usage Data, and Marketing and Communications Data.',
      ],
    },
    {
      title: 'How is Your Personal Data Collected?',
      p1: '',
      listItems: [],
      listPoints: [
        'We collect data through direct interactions (e.g., when you use our services or contact us), as well as through automated technologies (e.g., cookies when you interact with our website).',
      ],
    },
    {
      title: 'How We Use Your Personal Data',
      p1: '',
      listItems: [],
      listPoints: [
        'We will use your personal data in accordance with UAE law, including but not limited to fulfilling contracts, for our legitimate interests, and complying with legal obligations.',
      ],
    },
    {
      title: 'Data Security',
      p1: 'We implement appropriate security measures to protect your personal data and comply with UAE data protection standards.',
      listItems: [],
      listPoints: [],
      afterListPointsLine: 'For more details, please refer to our',
    },
    {
      title: 'Data Retention',
      p1: 'Your personal data will be retained in accordance with UAE laws and only for as long as necessary for the purposes set out in this policy.',
      listItems: [],
      listPoints: [],
    },
    {
      title: 'Your Legal Rights',
      p1: 'Under UAE law, you have rights in relation to your personal data, including access, correction, and in some cases, erasure and the right to object to processing.',
      listItems: [],
      listPoints: [],
    },
    {
      title: 'Changes to the Privacy Policy',
      p1: 'This policy may be updated to remain compliant with UAE laws and regulations. We will notify you of any significant changes.',
      listItems: [],
      p2:''
    },
    {
      title: 'Contact Us',
      p3: 'For more information, questions, or complaints, please contact us via',
    },
  ];

  return (
    <>
      <div className="w-full bg-[#EFE8DF] ">
        <Header />
        <div className="lg:px-10 px-2.5">
          <div className="sm:flex items-center justify-between mt-[72px]">
            <div className="flex items-center gap-2.5">
              <IconSvg icon="horizontalDoubleHeadLongArrow" className="lg:w-[152px] w-[77px] inline-block" />
              <h1 className="font-inter font-medium lg:text-6xl sm:text-3xl text-2xl lg:leading-[72px] sm:leading-9 leading-[28.8px] text-[#0F0F0F]">
                PRIVACY POLICY
              </h1>
            </div>
            <p className="font-light font-inter lg:text-2xl sm:text-sm text-[12px] lg:leading-[28.8px] sm:leading-[16.8px] leading-[14.4px] sm:pt-0 pt-3">
              <span className="font-inter font-bold text-[#0F0F0F]">Last modified:</span> 1
              June 2024
            </p>
          </div>

          <div className="mt-[70x] lg:w-[861px] w-full">
            {termsOfUse.map((term, i) => (
              <>
                <div className="mt-[34px]">
                  {term.title && (
                    <h4 className="font-inter font-bold lg:text-2xl sm:text-xl text-lg lg:leading-[28.8px] sm:leading-6 leading-[21.6px] text-[#0F0F0F]">
                      {term.title}
                    </h4>
                  )}
                  {term.p1 && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p1}
                    </p>
                  )}
                  {term?.listItems &&
                    term?.listItems?.length >= 1 &&
                    term?.listItems?.map((listItem, i) => (
                      <>
                        <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A] mt-[34px]">
                          {listItem.listLine}
                        </p>
                        <ul className="list-disc pl-[29px]">
                          {listItem.listItemPoints.map((point, pointIndex) => (
                            <li
                              className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                              key={pointIndex}
                            >
                              {point}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  {term?.listPoints && (
                    <ul className="list-disc pl-[29px] mt-5">
                      {term?.listPoints?.length >= 1 &&
                        term.listPoints.map((listPoint, listPointIndex) => (
                          //   <div className="flex items-start" key={listPoint + listPointIndex}>
                          //     <div className="h-1 w-1 rounded-full bg-[#4A4A4A] mx-3"></div>
                          <li
                            className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]"
                            key={listPoint + listPointIndex}
                          >
                            {listPoint}
                          </li>
                          //   </div>
                        ))}
                    </ul>
                  )}
                  {term.afterListPointsLine && (
                    <p className="font-light font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] text-[#4A4A4A]">
                      {term.afterListPointsLine}{' '}
                      <span
                        className="font-inter font-bold text-[#C56B48] border-b inline-block border-[#C56B48] cursor-pointer "
                        onClick={() => navigate('/')}
                      >
                        hi@xplorius.com
                      </span>
                    </p>
                  )}
                  {term.p2 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p2}
                    </p>
                  )}
                  {term.p3 && (
                    <p className="font-light  font-inter lg:text-lg sm:text-base text-sm  lg:leading-[27px] sm:leading-6 leading-[21px] pt-5 text-[#4A4A4A]">
                      {term.p3}{' '}
                      <a
                        href="mailto:hi@xplorius.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <span
                          className="font-inter font-bold text-[#C56B48] border-b inline-block border-[#C56B48] cursor-pointer "
                          // onClick={() => navigate('/')}
                        >
                          hi@xplorius.com
                        </span>
                      </a>
                    </p>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="lg:mt-[204px] sm:mt-[30px] mt-6 w-full">
          <div className="border-b border-[#4A4A4A]"></div>
          <div className="sm:mt-[46px] mt-6 lg:pb-[46px] lg:px-10 px-[15.5px]">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
