import { useContext } from 'react';
import { IconSvg } from '../../IconSvg';
import { FullPageModal } from '../../full-page-modal/FullPageModal';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

interface Props {
  isPreview?: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit?: (groupIndex: any) => void;
  previewGroupId?: string;
}

export default function LoginSignupModal({
  isOpen,
  onCancel,
  onSubmit,
  isPreview,
  previewGroupId,
}: Props) {
  const navigate = useNavigate();
  const { fetchProfile, logout, loginWithRedirect, appConfig, userAuth0, accessToken, profile } =
    useContext(AuthContext);
  const onSubmitHandler = () => {
    console.log('asfdsf');
  };

  const signupHandler = () => {
    // void loginWithRedirect({
    //   appState: { returnTo: `/sign-up` },
    //   screen_hint: 'signup',
    // });
    navigate('/sign-up');
  };

  return (
    <FullPageModal
      isStatic={true}
      isOpen={isOpen}
      onSubmit={onSubmitHandler}
      close={onCancel}
      withCrossBtn
      className="!p-0 !m-0 !bg-[#EFE8DF]"
    >
      <div>
        {/* <IconSvg icon='topLeftCornerOfLoginSignupSmall' /> */}
        <div className="sm:block hidden">
          <div
            className="flex items-start justify-between p-4  h-[337px] "
            style={{
              backgroundImage: `url("/assets/images/topLeftCornerOfLoginSignupSmall.svg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
            }}
          >
            <IconSvg icon="logoBlack" className="w-[109px] cursor-pointer" onClick={() => onCancel()} />
            <IconSvg icon="cross" className='cursor-pointer' onClick={onCancel} />
          </div>
        </div>

        {/* This section for mobile start */}
        <div className="sm:hidden block">
          <div
            className="flex items-start justify-between p-2  h-[263px] "
            style={{
              backgroundImage: `url("/assets/images/topLeftCornerOfLoginSignupMobile.svg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
            }}
          >
            <IconSvg icon="logoBlack" className="w-[109px] cursor-pointer" onClick={() => onCancel()} />
            <IconSvg icon="cross" className='cursor-pointer' onClick={onCancel} />
          </div>
        </div>
        {/* This section for mobile end */}
        <div className="sm:block hidden">
          <div
            className="flex justify-center h-[519px]"
            style={{
              backgroundImage: `url("/assets/images/bottomRightCornerOfLoginSignupSmall.svg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          >
            <div className="mt-20">
              <div className="flex items-start  w-[156px]">
                <p className="pt-2">01</p>
                <p
                  className="font-inter font-medium text-3xl text-[#0F0F0F] pl-4 cursor-pointer"
                  onClick={() => navigate('/login')}
                >
                  LOGIN
                </p>
              </div>
              <div className="flex items-start  w-[156px]">
                <p className="pt-2">02</p>
                <p
                  className="font-inter font-medium text-3xl text-[#0F0F0F] pl-4 cursor-pointer"
                  onClick={() => signupHandler()}
                >
                  SIGN UP
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* This section for mobile start */}
        <div className="sm:hidden block">
          <div
            className="flex justify-center h-[325px]"
            style={{
              backgroundImage: `url("/assets/images/bottomRightCornerOfLoginSignupMobile.svg")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          >
            <div className="mt-20">
              <div className="flex items-start  w-[156px]">
                <p className="pt-2">01</p>
                <p
                  className="font-inter font-medium text-3xl text-[#0F0F0F] pl-4 cursor-pointer"
                  onClick={() => navigate('/login')}
                >
                  LOGIN
                </p>
              </div>
              <div className="flex items-start  w-[156px]">
                <p className="pt-2">02</p>
                <p
                  className="font-inter font-medium text-3xl text-[#0F0F0F] pl-4 cursor-pointer"
                  onClick={() => signupHandler()}
                >
                  SIGN UP
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* This section for mobile end */}

        <div className="flex items-center justify-center lg:gap-8 gap-5 pb-6">
          <a
           href="https://www.instagram.com/travelwithxplorius/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconSvg icon="instagram" />
          </a>
          <a
            href="https://discord.com/invite/xploriusxyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconSvg icon="game" />
          </a>
          <a href="https://x.com/XPLORIUSxyz" target="_blank" rel="noopener noreferrer">
            <IconSvg icon="twitter" />
          </a>
          <div className="sm:block hidden">
            <IconSvg icon="youtube" />
          </div>
        </div>
      </div>
    </FullPageModal>
  );
}
