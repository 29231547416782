import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/hooks';
import { AuthContext } from 'src/contexts';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { Button, IconSvg } from 'src/components/common';
import { CouponConfirmationModal } from 'src/components/coupon/Modal/CouponConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { CouponType } from 'src/types';
import { PlansCouponModal } from 'src/components/coupon/Modal/PlansCouponModal';

const formatUnixTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-US', options);
};

export function Coupon() {
  const navigate = useNavigate();
  const [isOpenConfimationModal, setIsOpenConfimationModal] = useState(false);
  const [isOpenPlansCouponModal, setIsOpenPlansCouponModal] = useState(false);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const { accessToken, profile } = useContext(AuthContext);
  const [coupons, setCoupons] = useState<CouponType[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [nextStartingAfter, setNextStartingAfter] = useState('');
  const [previousStartingBefore, setPreviousStartingBefore] = useState('');
  const [id, setId] = useState('');
  const [couponId, setCouponId] = useState('');
  const notify = useNotification();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (error) {
      notify(error, 'error');
    }
  }, [error]);

  const getCouponsHandler = () => {
    apiRequestHandler(
      {
        url: `coupon`,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setCoupons(data.data);
        setHasMore(data?.hasMore);
        setNextStartingAfter(data?.nextStartingAfter);
        setPreviousStartingBefore(data?.previousStartingBefore);
      },
    );
  };
  useEffect(() => {
    if (accessToken) getCouponsHandler();
  }, []);

  const confirmationModalHandler = () => {
    setIsOpenConfimationModal(prev => !prev);
  };
  // const coupons = [
  //   {
  //     couponId: '1',
  //     name: 'welcome50',
  //     amountOrPerncentage: '50%',
  //   },
  //   {
  //     couponId: '1',
  //     name: 'welcome50',
  //     amountOrPerncentage: '50%',
  //   },
  // ];
  const onSubmitHandler = (id: string) => {
    apiRequestHandler(
      {
        url: `coupon/${id}`,
        method: 'DELETE',
        accessToken,
      },
      (data: any) => {
        // setCoupons(data);
        getCouponsHandler();
        notify('Coupon deleted successfully', 'success');
        confirmationModalHandler();
      },
    );
  };

  const onPlansSubmitHandler = (plans: string[]) => {
    if (plans.length == 0) {
      notify('Please select at least one plan', 'error');
      return;
    }

    const payload = {
      planIds: plans,
      couponId: id,
    };
    apiRequestHandler(
      {
        url: 'coupon',
        method: 'POST',
        accessToken,
        payload,
      },
      (data: any) => {
        // setPlans(data);
        getCouponsHandler();
        couponPlansOpenCloseHandler();
        notify('Plan add in coupon successfully', 'success');
      },
    );
  };

  const getCouponsByPaginationsHandler = (keyName: string, value: string) => {
    console.log({ keyName });

    apiRequestHandler(
      {
        url: `coupon?${keyName}=${value}`,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setCoupons(data.data);
        setHasMore(data?.hasMore);
        setNextStartingAfter(data?.nextStartingAfter);
        setPreviousStartingBefore(data?.previousStartingBefore);
      },
    );
  };
  const couponPlansOpenCloseHandler = () => {
    setIsOpenPlansCouponModal(prev => !prev);
  };

  return (
    <>
      <div className="">
        <div className="flex items-center justify-between">
          <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
            Coupons
          </h2>
          <Button
            size="small"
            color="primary"
            type="submit"
            label={
              <p className="uppercase text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px] ">
                New coupon
              </p>
            }
            className="sm:px-6 px-0 sm:mt-0 mt-3 sm:w-auto w-[150px] rounded-[5px]"
            onClick={() => navigate('/create-coupon')}
          />
        </div>
        <div className="sm:border  sm:border-[#959595] border-collapse rounded-[5px] lg:p-7 sm:p-6 p-0 lg:mt-5 sm:mt-3 mt-5">
          <div className="">
            <div className="inline-block min-w-full py-0 align-middle sm:px-0 lg:px-0">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="">
                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start font-normal">
                      <span className="">COUPON</span>
                    </th>

                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start w-[180px] font-normal">
                      <span className="">NAME</span>
                    </th>
                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start font-normal">
                      Products
                    </th>
                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start  font-normal">
                      <span className="">TERMS</span>
                    </th>
                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start font-normal">
                      REDEMPTIONS
                    </th>
                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start font-normal">
                      EXPIRES
                    </th>

                    <th className="p-2 whitespace-nowrap text-sm text-[#000000A3] text-start w-[115px] font-normal">
                      <span className="">Action</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="">
                  {coupons.map(coupon => (
                    <tr className="border-y border-y-[#959595]" key={coupon.id}>
                      <td className="p-2 text-sm text-start">
                        <div className="text-[#4A4A4A]">{coupon.couponId}</div>
                      </td>
                      <td className="p-2 text-sm text-start">
                        <div className="text-[#4A4A4A]">{coupon.couponName}</div>
                      </td>
                      <td className="p-2">
                        {coupon?.plans?.[0]?.name || coupon?.plans?.[1]?.name ? (
                          `${coupon?.plans?.[0]?.name}${
                            coupon?.plans?.[1]?.name ? `,${coupon?.plans?.[1]?.name}` : ''
                          }`
                        ) : (
                          <IconSvg
                            icon="plusOrange"
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => {
                              couponPlansOpenCloseHandler();
                              setId(coupon.couponId);
                            }}
                          />
                        )}
                      </td>
                      <td className="p-2">
                        <div className="text-sm font-normal cursor-pointer text-[#4A4A4A]">
                          {coupon.amount_off ? `$${coupon.amount_off}` : `${coupon.percent_off}%`}
                        </div>
                      </td>
                      <td className="p-2 text-[#4A4A4A]">
                        {coupon.max_redemptions
                          ? coupon.times_redeemed + '/' + coupon.max_redemptions
                          : coupon.times_redeemed}
                      </td>
                      <td className="p-2 text-[#4A4A4A]">
                        {coupon?.redeem_by ? formatUnixTimestamp(coupon?.redeem_by) : '-'}
                      </td>
                      <td className="p-2">
                        <div className="flex items-center gap-4">
                          {/* <div>
                            <IconSvg icon="edit" className='cursor-pointer' onClick={() => navigate(`/update-coupon/${coupon.id}`)} />
                          </div> */}
                          <div>
                            <IconSvg
                              icon="deleteOrange"
                              className="h-6 w-6 cursor-pointer"
                              onClick={() => {
                                setCouponId(coupon.couponId);
                                confirmationModalHandler();
                              }}
                            />
                          </div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-4 px-3">
              <p>{coupons.length} results</p>
              <div className="flex items-center gap-2">
                <p
                  className={`border border-[#959595] px-2 rounded-[5px] font-inter ${
                    count >= 1 && previousStartingBefore ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                  onClick={() => {
                    count >= 1 &&
                      previousStartingBefore &&
                      getCouponsByPaginationsHandler('startingBefore', previousStartingBefore);
                    count >= 1 && setCount(prev => prev - 1);
                  }}
                >
                  Previous
                </p>
                <p
                  className={`border border-[#959595] px-2 rounded-[5px] font-inter mt-0 ${
                    hasMore ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                  onClick={() => {
                    hasMore && getCouponsByPaginationsHandler('startingAfter', nextStartingAfter);
                    hasMore && setCount(prev => prev + 1);
                  }}
                >
                  Next
                </p>
              </div>
            </div>
          </div>
          {/* <div>
            {coupons.map((coupon, i) => (
              <div
                className="grid grid-cols-12 p-4 bg-[#C56B481A] bg-opacity-75 rounded-lg mb-0.5"
                key={i}
              >
                <div className="col-span-4 flex items-center gap-5">
                  <div className="">{coupon.couponId}</div>
                  <div>
                    <p className="font-inter font-medium text-base">{coupon.name}</p>
                  </div>
                </div>
                <div className="col-span-4 flex items-center justify-center gap-5">
                  <p className="font-inter font-semibold text-base">{coupon.amountOrPerncentage}</p>
                </div>
                <div className="col-span-4 flex items-center justify-end gap-1">
                  <div className=" flex justify-center items-center">
                    <IconSvg
                      icon="edit"
                      //  onClick={() => handleDownload(coupon.url)}
                    />
                  </div>
                  <IconSvg
                    icon="deleteOrange"
                    className="cursor-pointer h-6 w-6"
                    onClick={confirmationModalHandler}
                  />
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <CouponConfirmationModal
        isOpen={isOpenConfimationModal}
        onCancel={confirmationModalHandler}
        id={couponId}
        onSubmitHandler={onSubmitHandler}
      />
      <PlansCouponModal
        isOpen={isOpenPlansCouponModal}
        onCancel={couponPlansOpenCloseHandler}
        couponId={id}
        onSubmitHandler={onPlansSubmitHandler}
      />
      {isLoading && <LoadingSpinner />}
    </>
  );
}
