import React, { ChangeEvent, ReactNode } from 'react';
import { IconSvg } from '../../ui/IconSvg';
import styles from './Checkbox.module.scss';
import clsx from 'clsx';

interface CustomCheckboxProps {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  isError?: boolean;
  errorMessage?: string;
  label?:string | ReactNode;
  id:string;
  isDisabled?: boolean;
}

export const Checkbox = ({
  checked,
  name,
  onChange,
  isError,
  errorMessage,
  label,
  id,
  isDisabled
}: CustomCheckboxProps) => {
  return (
    <>
      <div className="flex items-center gap-2.5">
        <div className="relative  cursor-pointer">
          <input
          className={clsx(
            isDisabled && styles['input-disabled'],
             'relative w-5 h-5 transition-all border-[2px] border-[#4A4A4A] rounded-[3px] appearance-none cursor-pointer peer checked:border-[#4A4A4A] checked:bg-transparent'
          )}
            id={id}
            type="checkbox"
            // className=""
            checked={checked}
            onChange={onChange}
            name={name}
          />

          <div className="absolute text-white transition-opacity -translate-x-1/2 -translate-y-1/2 opacity-0 pointer-events-none duration-10 top-[3px] left-3 peer-checked:opacity-100">
            <svg
              width="18"
              height="25"
              viewBox="0 0 18 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 14.3521L7.78788 23.3521L17 0.352051" stroke="#4A4A4A" />
            </svg>
          </div>
        </div>
        <label
          htmlFor={id}
          className="font-inter cursor-pointer text-[13px] font-light leading[15.6] text-[#4A4A4A]"
        >
          {label}
        </label>
      </div>
      {/* {isError && (
        <div className="flex items-center gap-4 mt-2.5">
          {' '}
          <IconSvg icon="attentionCircle" />
          <p className={styles['error-text']}>{errorMessage}</p>
        </div>
      )} */}
    </>
  );
};
