import { Footer } from 'src/components/common';
import { Header } from './header/Header';
import { HeaderMobile } from './header/HeaderMobile';
import { Sidebar } from './sidebar/Sidebar';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

interface IProps {
  children: JSX.Element;
}
export function Layout({ children }: IProps) {
  const navigate = useNavigate()
  const {pathname} = useLocation();
  const { userAuth0, profile } = useContext(AuthContext);
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if(userAuth0?.sub && !profile?.account?.plan){
      return navigate("/profile")
    }
  }, [userAuth0?.sub, profile?.account?.plan, location.pathname])
  return (
    <>
      <div className=" bg-[#EFE8DF] min-h-screen w-full">
        <div className="lg:px-8 lg:py-0 py-4 px-2.5">
          <Header />
          <HeaderMobile />
          <div className="flex w-full h-full lg:mt-14 mt-0 gap-10">
            <div className=" w-[350px] lg:block hidden">
              <Sidebar />
            </div>
            <div
              className="w-auto mx-auto  overflow-auto no-scrollbar flex-grow lg:mt-0 sm:mt-10 mt-[22px]"
              style={{ width: 'calc(100% - 350px)' }}
            >
              {children}
            </div>
          </div>
        </div>
        <div className="border-t border-[#959595] lg:mt-16 mt-10">
          <div className="lg:px-8 px-4 lg:pt-16 pt-10 pb-12">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
