import React from 'react';
import clsx from 'clsx';
import styles from './InputText.module.scss';
import { Icons } from 'src/types/icons';
import { IconSvg } from 'src/components/common';

interface Props {
  id?: string;
  name?: string;
  value?: string | number;
  type?: string;
  icon?: Icons;
  onChange: (input: { name: string; value: string }) => void;
  label?: string;
  wrapperStyles?: string;
  isError?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onBlur?: (input: { name: string; value: string }) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // New property for onKeyDown
  min?: number;
  max?: number;
}

export function InputText({
  id,
  name,
  label,
  value,
  type = 'text',
  icon,
  onChange,
  wrapperStyles,
  isError,
  isSuccess,
  isDisabled,
  errorMessage,
  placeholder,
  onBlur,
  onKeyDown,
  min,
  max,
}: Props) {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange({ name, value });
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!onBlur) return;
    const { name, value } = event.target;
    onBlur({ name, value });
  };

  return (
    <div className={wrapperStyles}>
      {label && (
        <label className={styles['label']} htmlFor={name}>
          {label}
        </label>
      )}

      <div className="relative">
        <input
          className={clsx(
            styles['input'],
            isError && styles['input-invalid'],
            isDisabled && styles['input-disabled'],
          )}
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          onKeyDown={onKeyDown}
          autoComplete={'off'}
          max={max}
          min={min}
        />

        {icon && <IconSvg icon={icon} className="absolute inset-y-0 right-2.5 my-auto" />}
      </div>

      {isError && (
        <div className="flex items-center gap-4 mt-2.5">
          {' '}
          <IconSvg icon="attentionCircle" />
          <p className={styles['error-text']}>{errorMessage}</p>
        </div>
      )}

      {isSuccess && (
        <div className="flex items-center gap-4 mt-2.5">
          {' '}
          <div className='h-[25px] w-[25px]'>
          <svg
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            fill="#078D45"
          >
            <path d="M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm6.927 8.2l-6.845 9.289a1.011 1.011 0 01-1.43.188l-4.888-3.908a1 1 0 111.25-1.562l4.076 3.261 6.227-8.451a1 1 0 111.61 1.183z"></path>
          </svg>
          </div>
          <p className={styles['success-text']}>{errorMessage}</p>
        </div>
      )}
    </div>
  );
}
