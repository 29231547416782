import { Button, IconSvg } from 'src/components/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/hooks';
import { AuthContext } from 'src/contexts';
import { LoadingSpinner } from 'src/components/common/ui/loading-spinner/LoadingSpinner';
import { Checkbox, InputText, SelectComponent } from 'src/components/common/form';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateCouponType } from 'src/types';

interface Coupon {
  name: string;
  id: string;
  duration: string;
  biannual: string;
  yearly: string;
  discountType: string;
  percentageOff?: string;
  amountOff?: string;
  redemptionLimit: string;
  timeRedemed?: string;
  // limitTotalTime?: boolean;
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  duration: Yup.string().required('Duration is required'),
  biannual: Yup.string().optional(),
  yearly: Yup.string().optional(),
  discountType: Yup.string()
    .oneOf(['percentageOff', 'amountOff'], 'Please select a discount type')
    .required('Discount type is required'),
  percentageOff: Yup.string().when('discountType', {
    is: 'percentageOff',
    then: Yup.string()
      .required('Percentage off is required')
      .matches(/^\d+$/, 'Percentage off must be a number')
      .test(
        'max-100',
        'Percentage off cannot be more than 100',
        value => !value || Number(value) <= 100,
      ),
  }),
  amountOff: Yup.string().when('discountType', {
    is: 'amountOff',
    then: Yup.string()
      .required('Amount off is required')
      .matches(/^\d+$/, 'Amount off must be a number'),
  }),
  id: Yup.string().required('ID is required'),
  redemptionLimit: Yup.string()
    .oneOf(['timeRedemed'], 'Please select a redemption limit')
    .required('Redemption limit is required'),
  timeRedemed: Yup.string().when('redemptionLimit', {
    is: 'timeRedemed',
    then: Yup.string()
      .required('Time redemption is required')
      .matches(/^\d+$/, 'Time redemption must be a number'),
  }),
});

export function UpdateCoupon() {
  const [submitButton, setSubmitButton] = useState(false);
  const [plans, setPlans] = useState<any>([]);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();
  const { accessToken, profile } = useContext(AuthContext);
  const notify = useNotification();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState<UpdateCouponType>()
  const {id} = useParams()


  const getCouponHandler = () => {
    apiRequestHandler(
      {
        url: 'coupon/' + id,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setCoupon(data);
      },
    );
  };
  useEffect(() => {
    if (accessToken && id) getCouponHandler();
  }, [id]);

  const formInputs = [
    {
      name: 'name',
      label: 'Name',
      placeholder: '',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'id',
      label: 'ID',
      placeholder: '',
      disabled: true,
      textArea: false,
      multiSelect: false,
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: coupon?.name || '',
      id: coupon?.stripeCoupon?.id || '',
      duration: coupon?.stripeCoupon?.duration || '',
      biannual: '',
      yearly: '',
      discountType: coupon?.stripeCoupon?.amount_off ? 'amountOff' : 'percentageOff',
      percentageOff: coupon?.stripeCoupon?.percent_off || '',
      amountOff: coupon?.stripeCoupon?.amount_off || '',
      redemptionLimit: 'timeRedemed',
      timeRedemed:  coupon?.stripeCoupon?.max_redemptions|| '',
      // limitTotalTime: false,
    },
    validationSchema,
    onSubmit: values => {
      console.log(values, 'sub');
      const planIds = [];
      if (values.biannual) {
        planIds.push(values.biannual);
      }
      if (values.yearly) {
        planIds.push(values.yearly);
      }
      const payload = {
        couponId: values.id,
        planIds: planIds,
        ...(values.amountOff && {percentOff: +values.amountOff}),
        ...(values.percentageOff && {percentOff: +values.percentageOff}),
        maxRedemptions: +values.timeRedemed,
        name: values.name,
        duration: values.duration
      };
      apiRequestHandler(
        {
          url: 'coupon',
          method: 'POST',
          accessToken,
          payload,
        },
        (data: any) => {
          // setPlans(data);
          navigate("/coupons")

          notify('Coupon created successfully', 'success');
        },
      );
    },
  });
  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    setValues,
    touched,
    resetForm,
    setFieldError,
  } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (name === 'percentageOff' || name === 'amountOff') {
      setFieldValue('discountType', name);
      setFieldValue(name, checked ? '' : '');
    } else {
      setFieldValue(name, checked);
    }
  };

  const handlePlansCheckboxChange = (name: string, value: string) => {
    const currentValue = values[name as keyof Coupon];
    setFieldValue(name, currentValue === value ? '' : value);
  };
  const durationOption = [
    {
      label: 'Forever',
      value: 'forever',
    },
    {
      label: 'Once',
      value: 'once',
    },
  ];
  const handleRedemptionLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    // setFieldValue(name, checked);
    if (name === 'timeRedemed') {
      setFieldValue('redemptionLimit', name);
      setFieldValue(name, checked ? '' : '');
      if (checked) {
        setFieldError('redemptionLimit', '');
        setFieldError('timeRedemed', '');
      }
    } else {
      setFieldValue(name, checked);
    }
  };

  const getPlansHandler = () => {
    apiRequestHandler(
      {
        url: 'payment/prices',
        method: 'GET',
      },
      (data: any) => {
        setPlans(data);
      },
    );
  };

  useEffect(() => {
    getPlansHandler();
  }, []);

  const plansOptions = [
    {
      label: 'BIANNUAL',
      name: 'biannual',
      value: plans[0]?.id,
      amount: plans[0]?.amount ? parseInt(plans[0]?.amount, 10) : 0,
    },
    {
      label: 'YEARLY',
      name: 'yearly',
      value: plans[1]?.id,
      amount: plans[1]?.amount ? parseInt(plans[1]?.amount, 10) : 0,
    },
  ];

  return (
    <>
      <div className="">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Update a coupon
        </h2>
        <form
          onSubmit={e => {
            e.preventDefault();
            setSubmitButton(true);
            handleSubmit();
          }}
        >
          <div className="sm:border  sm:border-[#959595] border-collapse rounded-[5px] lg:p-7 sm:p-6 p-0 lg:mt-5 sm:mt-3 mt-5">
            <div className="sm:w-[600px] w-full">
              {formInputs.map((sub, i) => (
                <div className={` w-full ${i !== 0 ? 'mt-5' : ''}`} key={i}>
                  {
                    <InputText
                      id={sub.name}
                      name={sub.name}
                      label={sub.label}
                      placeholder={sub.placeholder}
                      value={values[sub.name as keyof Coupon]}
                      onChange={onChangeHandler}
                      isDisabled={sub.disabled}
                      isError={
                        touched[sub.name as keyof Coupon] && !!errors[sub.name as keyof Coupon]
                      }
                      errorMessage={errors[sub.name as keyof Coupon]}
                    />
                  }
                </div>
              ))}

              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Type
                </p>
                <div className="">
                  <Checkbox
                    id="percentageOff"
                    checked={values.discountType === 'percentageOff'}
                    onChange={handleCheckboxChange}
                    name="percentageOff"
                    label={'Percentage discount'}
                    isDisabled={true}
                  />
                </div>
                <div className="pt-[18px] flex items-center gap-2.5">
                  <Checkbox
                    id="amountOff"
                    checked={values.discountType === 'amountOff'}
                    onChange={handleCheckboxChange}
                    name="amountOff"
                    label={'Fixed amount discount'}
                    isDisabled={true}
                  />
                </div>
                {touched['discountType'] && !!errors['discountType'] && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      {errors['discountType']}
                    </p>
                  </div>
                )}
              </div>
              {values.discountType === 'amountOff' && (
                <div className="mt-5">
                  <InputText
                    id={'amountOff'}
                    name={'amountOff'}
                    label={'Amount off'}
                    value={values.amountOff}
                    placeholder={''}
                    onChange={onChangeHandler}
                    isError={touched.amountOff && !!errors.amountOff}
                    errorMessage={errors.amountOff}
                    isDisabled={true}
                  />
                </div>
              )}
              {values.discountType === 'percentageOff' && (
                <div className="mt-5">
                  <InputText
                    id={'percentageOff'}
                    name={'percentageOff'}
                    value={values.percentageOff}
                    label={'Percentage off'}
                    placeholder={''}
                    icon="percentage"
                    onChange={onChangeHandler}
                    isError={touched.percentageOff && !!errors.percentageOff}
                    errorMessage={errors.percentageOff}
                    isDisabled={true}
                  />
                </div>
              )}
              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mt-5">
                  Plans
                </p>
                {plansOptions.map((plan, index: number) => (
                  <div className="pt-[18px]" key={index}>
                    <Checkbox
                      id={plan.name}
                      checked={!!values[plan.name as keyof Coupon]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePlansCheckboxChange(e.target.name, plan.value)
                      }
                      name={plan.name}
                      label={plan.label}
                    />
                  </div>
                ))}
                {submitButton && !values.biannual && !values.yearly && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      Please select at least one plan
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-5">
                <SelectComponent
                  id={'duration'}
                  name={'duration'}
                  label={'Duration'}
                  placeholder={''}
                  options={durationOption || []}
                  value={durationOption?.find(c => c.value === values['duration'])}
                  onChange={onChangeHandler}
                  isError={touched['duration'] && !!errors['duration']}
                  errorMessage={errors['duration']}
                  disabled={true}
                />
              </div>
              <div className="">
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Redemption limits
                </p>
                <Checkbox
                  id="timeRedemed"
                  checked={values.redemptionLimit === 'timeRedemed'}
                  onChange={handleRedemptionLimitChange}
                  name="timeRedemed"
                  label={'Limit the total number of times this coupon can be redeemed'}
                  isDisabled={true}
                />
                {touched['redemptionLimit'] && !!errors['redemptionLimit'] && (
                  <div className="flex items-center gap-4 mt-2.5">
                    <IconSvg icon="attentionCircle" />
                    <p className="font-semibold text-sm font-inter leading-[17.81px] text-[#9B2626] mt-1">
                      {errors['redemptionLimit']}
                    </p>
                  </div>
                )}
              </div>
              {values.redemptionLimit === 'timeRedemed' && (
                <div className="mt-5">
                  <InputText
                    id={'timeRedemed'}
                    name={'timeRedemed'}
                    label={''}
                    placeholder={''}
                    value={values.timeRedemed}
                    tag={
                      <p className="font-inter font-light text-sm leading-6 text-[#4A4A4A] absolute inset-y-0 right-2.5 my-auto flex items-center">
                        time
                      </p>
                    }
                    isDisabled={true}
                    onChange={onChangeHandler}
                    isError={touched.timeRedemed && !!errors.timeRedemed}
                    errorMessage={errors.timeRedemed}
                  />
                </div>
              )}
              <div>
                <p className="font-inter text-sm leading-6 font-medium text-[#4A4A4A] mb-2 mt-5">
                  Add an expiration date
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4 mt-5">
              <Button
                size="small"
                color="yellow"
                type="submit"
                label={
                  <p className="uppercase text-[#0F0F0F] font-medium font-inter sm:text-lg text-[12px] sm:leading-[21.6px] leading-[14.4px]">
                    Submit
                  </p>
                }
                className="px-10 lg:w-auto lg:h-[40px] w-full"
              />
            </div>
          </div>
        </form>
      </div>
      {isLoading && <LoadingSpinner />}
    </>
  );
}
